import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { getProviders } from 'redux/deposit/getVoucherProvidersSlice';
import '../../../styles/_home-dashboard.scss';

const Home = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getAgentId = () => {
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    return userInfo && userInfo.agentId;
  };
  const { homeList } = useSelector((store) => store.homeList);
  return (
    <>
      <div className="list-dashboard">
        <div className="content">
          <span className="d-block d-md-none">Menu</span>
          <ul className="list-unstyled">
            {homeList?.length > 0 ? homeList.map((option) => {
              if (option.id === 7) {
                return (
                  <NavLink
                    to={`/dashboard/${option.code}`}
                    key={option.id}
                    className="detail-link"
                    onClick={() => dispatch(getProviders(getAgentId()))}
                  >
                    <div className="card">
                      <div className="card-img">
                        <img src={option.image} alt="" />
                      </div>
                      {t(option.name)}
                    </div>
                  </NavLink>
                );
              }
              return (
                <NavLink
                  to={`/dashboard/${option.code}`}
                  className={`detail-link${option.id === 4 ? ' disable' : ''}`}
                  key={option.id}
                >
                  <div className="card">
                    <div className="card-img">
                      <img src={option.image} alt="" />
                    </div>
                    {t(option.name)}
                  </div>
                </NavLink>
              );
            }) : null}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Home;
