import React, { useState } from 'react';
import '../../../../styles/_home-detail.scss';
import { verifyTicket } from 'redux/verify-ticket/verifyTicketSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import translationKeys from 'translations/translationKeys';
import ticketwhite from '../../../../assets/images/mathys/svg/ticketwhite.svg';

const VerifyTicket = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const [disabled, setDisabled] = useState(true);
  const handleChange = () => {
    const ticketNumber = document.getElementById('ticketNumber').value;
    if (ticketNumber !== '') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const ticketNumber = e.target.elements.ticketNumber.value;
    localStorage.setItem('ticketNumber', ticketNumber);
    localStorage.setItem('payRefCode', `%5B%22${ticketNumber}%22%5D`);
    // const digitRegex = /^\d{12}$/;
    // // Check if the ticketNumber matches the regular expression
    // if (!digitRegex.test(ticketNumber)) {
    //   setMsg(true);
    //   setAlertMessage('Veuillez entrer un numéro valide.');
    //   return;
    // }
    dispatch(verifyTicket({
      refCode: ticketNumber,
      agentId: userInfo.agentId,
    }));
  };
  const { msg135, msg18, msg71 } = translationKeys;
  return (
    <>
      <div className="details verifier-ticket">
        <div className="header">
          <img src={ticketwhite} alt="" />
          {t(msg135)}
        </div>
        <div className="content verify-content">
          <form action="" onSubmit={handleSubmit} className="verify-form">
            <div className="mb-3 verify-input-wrap">
              <label htmlFor="ticketNumber" className="form-label verify-label">
                {t(msg71)}
                <input
                  type="text"
                  className="form-control verify-input"
                  id="ticketNumber"
                  required
                  placeholder="Ex: 300614792988"
                  onChange={handleChange}
                />
              </label>
            </div>
            <button
              type="submit"
              className="btn btn-secondary verify-btn"
              disabled={disabled}
            >
              <span>{t(msg18)}</span>
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default VerifyTicket;
