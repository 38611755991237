import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { checkWithdrawObj, userObj } from 'components/common/requestBody';
import { checkWithdraw } from 'redux/withdrawal/checkWithdrawalSlice';
import { useTranslation } from 'react-i18next';
import translationKeys from 'translations/translationKeys';
import withdrawalwhite from '../../../../assets/images/mathys/svg/withdrawalwhite.svg';

const Withdrawal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const [disabled, setDisabled] = useState(true);
  const handleChange = () => {
    const codeClient = document.getElementById('codeClient').value;
    const codeOtp = document.getElementById('codeOtp').value;
    if (codeClient !== '' && codeOtp !== '') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };
  const checkWithdrawal = (e) => {
    e.preventDefault();
    const codeClient = e.target.elements.codeClient.value;
    const codeOtp = e.target.elements.codeOtp.value;
    dispatch(checkWithdraw({
      ...checkWithdrawObj,
      agentId: userInfo.agentId,
      agentPwd: userInfo.agentPwd,
      agentPhone: userInfo.agentPhone,
      imei: userObj.imei,
      cac: codeClient,
      wcd: codeOtp,
    }));
  };
  const {
    msg19, msg73, msg85, msg84, msg72, msg55,
  } = translationKeys;
  return (
    <>
      <div className="details history">
        <div className="header">
          <img src={withdrawalwhite} alt="" />
          {t(msg19)}
        </div>
        <div className="content">
          <form action="" onSubmit={checkWithdrawal} className="withdraw-form">
            <div className="content-grid">
              <div className="mb-3 input-wrap">
                <label htmlFor="codeClient" className="form-label ">
                  {t(msg72)}
                  <input
                    type="text"
                    className="form-control withdraw-input"
                    id="codeClient"
                    placeholder={t(msg84)}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label htmlFor="codeOtp" className="form-label ">
                  {t(msg73)}
                  <input
                    type="text"
                    className="form-control withdraw-input"
                    id="codeOtp"
                    placeholder={t(msg85)}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <button
                type="submit"
                className="btn btn-secondary"
                disabled={disabled}
              >
                <span>{t(msg55)}</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Withdrawal;
