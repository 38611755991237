import React from 'react';
import { useParams } from 'react-router-dom';
import '../../styles/_single-page.scss';
import PageNotFound from 'components/pageNotFound/PageNotFound';
import Home from './content/Home';
import JackPot from './content/JackPot';
import MeilleursParis from './content/top-matches/MeilleursParis';
import VerifyTicket from './content/home-details/VerifyTicket';
import History from './content/home-details/History';
import UniversalComponent from './content/UniversalComponent';
import TodayMatches from './content/today-matches/TodayMatches';
import MatchDetails from './content/match-details/MatchDetails';
// import Search from './content/home-details/Search';
import Deposit from './content/home-details/Deposit';
import Withdrawal from './content/home-details/Withdrawal';
import Solde from './content/home-details/Solde';

const data = [
  {
    id: 'home',
    component: <Home />,
  },
  {
    id: 'jackpot',
    component: <JackPot />,
  },
  {
    id: 'meilleurs-paris',
    component: <MeilleursParis />,
  },
  {
    id: 'verifier-ticket',
    component: <VerifyTicket />,
  },
  {
    id: 'history',
    component: <History />,
  },
  {
    id: 'matches',
    component: <UniversalComponent />,
  },
  {
    id: 'today-matches',
    component: <TodayMatches />,
  },
  {
    id: 'meilleures-competitions',
    component: <Home />,
  },
  {
    id: 'match-details',
    component: <MatchDetails />,
  },
  // {
  //   id: 'search',
  //   component: <Search />,
  // },
  {
    id: 'deposit',
    component: <Deposit />,
  },
  {
    id: 'Withdrawal',
    component: <Withdrawal />,
  },
  {
    id: 'solde',
    component: <Solde />,
  },
];
const SinglePage = () => {
  const params = useParams();
  const { id } = params;
  const match = data.find((item) => item.id === id);

  return (
    <div className="content">
      {
        match ? match.component : <PageNotFound />
      }
    </div>
  );
};
export default SinglePage;
