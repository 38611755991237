import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from 'translations/englilsh';
import fr from 'translations/french';
import LanguageDetector from 'i18next-browser-languagedetector'; // Import the language detector

i18n
  .use(initReactI18next)
  .use(LanguageDetector) // Use the language detector
  .init({
    debug: true,
    fallbackLng: 'fr', // Default fallback language if detection fails
    interpolation: {
      escapeValue: false,
    },
    resources: {
      fr: {
        translation: fr,
      },
      en: {
        translation: en,
      },
    },
  });

export default i18n;
