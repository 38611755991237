const formatDateTime = (unixTimestamp) => {
  // Convert the Unix timestamp (in milliseconds) to a Date object
  const date = new Date(unixTimestamp);

  // Extract the day, month, year, hours, and minutes
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Format the date as dd/mm/yyyy and time as hh:mm
  const formattedDateTime = `${day}/${month}/${year}, ${hours}:${minutes}`;

  return formattedDateTime;
};

export default formatDateTime;
