import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import { userObj } from 'components/common/requestBody';
import translationKeys from 'translations/translationKeys';
import getLanguage from 'components/helpers/getLanguage';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});

const { msg29 } = translationKeys;

export const getCategories = createAsyncThunk(
  'sports/getCategories',
  async (sportId, thunkAPI) => {
    const lang = getLanguage();
    const api = `/sport/${sportId}/0/categories?channel_id=${userObj.chl}&lang=${lang}`;
    const signatureParam = `/sport/${sportId}/0/categorieschannel_id=${userObj.chl}&lang=${lang}`;
    const url = `/${apiVersion2}${api}`;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}`)}`,
        },
      };
      const resp = await client.get(url, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(msg29);
    }
  },
);

const initialState = {

  categories: [],
  isLoading: true,
  error: '',
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(getCategories.fulfilled, (state, action) => ({
        ...state,
        categories: action.payload,
        isLoading: false,
      }))

      .addCase(getCategories.rejected, (state) => ({
        ...state,
        isLoading: false,
        error: msg29,
      }));
  },
});

export default categoriesSlice.reducer;
