import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import { userObj } from 'components/common/requestBody';
import getLanguage from 'components/helpers/getLanguage';
import getSavedRefereshToken from 'components/helpers/getSavedRefereshToken';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
export const getRefresToken = createAsyncThunk(
  'token/refreshToken',
  async (thunkAPI) => {
    const lang = getLanguage();
    const refreshToken = getSavedRefereshToken() || '';
    const obj = {
      refreshToken,
      channel: userObj.chl,
      lang,
    };
    const api = '/user/refreshToken';
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    try {
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
        'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${api}${JSON.stringify(obj)}`)}`,
      };
      const resp = await client.post(url, obj, { headers });
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('Une erreur est survenue sur le serveur');
    }
  },
);

const initialState = {
  token: '',
  isLoading: false,
  success: false,
  failed: false,
  msg: '',
  error: '',
};

const refreshTokenSlice = createSlice({
  name: 'refresh-token',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getRefresToken.pending, (state) => ({
        ...state,
        isLoading: true,
        success: false,
      }))
      .addCase(getRefresToken.fulfilled, (state, { payload }) => {
        let token;
        const { erc, data } = payload;
        if (erc === '1') {
          token = data.token;
          localStorage.setItem('expireAt', data.expire_at);
          localStorage.setItem('token', JSON.stringify(token));
        }
        return {
          ...state,
          token,
        };
      })

      .addCase(getRefresToken.rejected, (state) => ({
        ...state,
        success: false,
      }));
  },
});

export default refreshTokenSlice.reducer;
