/* eslint  max-len: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash, token } from 'components/helpers/base64AuthParams';
import getLanguage from 'components/helpers/getLanguage';
import translationKeys from 'translations/translationKeys';
import tokenText from 'components/helpers/tokenText';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const {
  msg29, msg89, msg120, msg91, msg128, msg129, msg131, msg150, msg155,
} = translationKeys;
export const directRecharge = createAsyncThunk(
  'Recharge/directRecharge',
  async (obj, thunkAPI) => {
    const lang = getLanguage();
    const requestParams = `lang=${lang}&agt=${obj.agentId}&amt=${obj.amt}&chl=${obj.chl}&acc=${obj.acc}&ime=${obj.imei}&vers=${obj.ver}&pwd=${obj.agentPwd}`;
    const api = `/ser_recharge_compte_client?${requestParams}`;
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    const signatureParam = `/ser_recharge_compte_client${requestParams}`;
    try {
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
        'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}${JSON.stringify(obj)}`)}`,
        Authorization: token ? `Bearer ${token}` : '',
      };
      const resp = await client.post(url, obj, { headers });
      return resp.data;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.erc) {
        return thunkAPI.rejectWithValue({
          message: error.response.data.message || 'An error occurred',
          erc: error.response.data.erc,
        });
      }
      return thunkAPI.rejectWithValue({
        message: 'An error occurred',
        erc: null,
      });
    }
  },
);

const transformArray = (obj) => {
  const transformedArray = [

    'Recharge  Direct',
    '--------------------------------',
    `N° DE COMPTE  : ${obj.acc}`,
    '--------------------------------',
    `MONTANT       : ${obj.rmt}`,
    '--------------------------------',
    `REF           : ${obj.ref}`,
    '--------------------------------',
    `AGENT         : ${obj.agt}`,
    `DATE          : ${obj.dte}`,
    '--------------------------------',
  ];
  return transformedArray;
};
const initialState = {
  depositData: [],
  isdepositLoading: false,
  successfetchDeposit: false,
  rechargeSuccess: false,
  failedRecharge: false,
  showRechargeConfirm: false,
  rechargeValue: 0,
  directRechargeMsg: '',
  errorRecharge: '',
};

const depositSlice = createSlice({
  name: 'make deposit',
  initialState,
  reducers: {
    makeDeposit: (state) => ({
      ...state,
      successfetchDeposit: true,
    }),
    hideDepositModal: (state) => ({
      ...state,
      isdepositLoading: false,
      successfetchDeposit: false,
      isError: '',
    }),
    showRechargeConfirmBox: (state, { payload }) => ({
      ...state,
      showRechargeConfirm: true,
      rechargeValue: payload,
      isdepositLoading: false,
      errorRecharge: '',
    }),
    hideRechargeConfirmBox: (state) => ({
      ...state,
      showRechargeConfirm: false,
      rechargeSuccess: false,
      isdepositLoading: false,
      errorRecharge: '',
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(directRecharge.pending, (state) => ({
        ...state,
        isdepositLoading: true,
        showRechargeConfirm: false,
      }))
      .addCase(directRecharge.fulfilled, (state, { payload }) => {
        let rechargeSuccess;
        let showRechargeConfirm;
        let errorRecharge;
        let depositData;
        if (payload && payload.vld && payload.vld === '1') {
          rechargeSuccess = true;
          showRechargeConfirm = false;
          errorRecharge = '';
          depositData = transformArray(payload);
        }
        if (payload && payload.erc && payload.erc === 'Deposit failed') {
          rechargeSuccess = false;
          showRechargeConfirm = true;
          errorRecharge = msg155;
        }
        if (payload && payload.erc && payload.erc === '109') {
          rechargeSuccess = false;
          showRechargeConfirm = true;
          errorRecharge = msg89;
        }
        if (payload && payload.erc && payload.erc === '116') {
          rechargeSuccess = false;
          showRechargeConfirm = true;
          errorRecharge = msg120;
        }
        if (payload && payload.erc && payload.erc === '90') {
          rechargeSuccess = false;
          showRechargeConfirm = true;
          errorRecharge = msg91;
        }
        if (payload && payload.erc && payload.erc === '158') {
          rechargeSuccess = false;
          showRechargeConfirm = true;
          errorRecharge = msg128;
        }
        if (payload && payload.erc && payload.erc === '157') {
          rechargeSuccess = false;
          showRechargeConfirm = true;
          errorRecharge = msg129;
        }
        if (payload && payload.erc && payload.erc === '168') {
          rechargeSuccess = false;
          showRechargeConfirm = true;
          errorRecharge = msg131;
        }
        if (payload && payload.erc && payload.erc === '023') {
          rechargeSuccess = false;
          showRechargeConfirm = true;
          errorRecharge = msg150;
        }
        return {
          ...state,
          depositData,
          isdepositLoading: false,
          successfetchDeposit: true,
          rechargeSuccess,
          showRechargeConfirm,
          errorRecharge,
          failedRecharge: false,
        };
      })
      .addCase(directRecharge.rejected, (state, { payload }) => {
        let errorRecharge;
        if (payload && payload.erc && payload.erc === '001') {
          errorRecharge = tokenText;
        } else { errorRecharge = msg29; }
        return {
          ...state,
          isdepositLoading: false,
          showRechargeConfirm: true,
          errorRecharge,
        };
      });
  },
});

export const {
  makeDeposit, hideDepositModal, showRechargeConfirmBox, hideRechargeConfirmBox,
} = depositSlice.actions;
export default depositSlice.reducer;
