import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import tokenText from 'components/helpers/tokenText';
import translationKeys from 'translations/translationKeys';
import axios from 'axios';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const getAgentCredetials = () => {
  const tempUserInfo = JSON.parse(sessionStorage.getItem('tempUserInfo'));
  return tempUserInfo;
};

const {
  msg29, msg30, msg27, msg25, msg31, msg32,
} = translationKeys;

export const resetPin = createAsyncThunk(
  'sessions/resetPin',
  async (obj, thunkAPI) => {
    const tempUserInfo = getAgentCredetials();
    const requestParams = `phone=${(obj.phone && obj.phone) || tempUserInfo.phone}&pin=${obj.pin}&newPin=${obj.newPin}&matricule=${(obj.matricule && obj.matricule) || tempUserInfo.agentId}&channel=${obj.channel}&str_From=${(obj.strFrom && obj.strFrom) || tempUserInfo.phone}&ime=${obj.ime}`;
    const api = `/agent-servicev2/ser_change_pin?${requestParams}`;
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    const signatureParam = `/agent-servicev2/ser_change_pin${requestParams}`;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}${JSON.stringify({})}`)}`,
        },
      };
      const resp = await client.post(url, {}, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.response.data.message || 'An error occurred',
        erc: error.response.data.erc,
      });
    }
  },
);

const initialState = {
  resetPinData: null,
  showResetModal: false,
  resetPinForm: false,
  resetLoading: false,
  resetSuccessMsg: '',
  resetMessage: '',
};

const resetPinSlice = createSlice({
  name: 'resetPIn',
  initialState,
  reducers: {
    showResetPinForm: (state) => ({
      ...state,
      resetPinForm: true,
    }),
    hideResetPinForm: (state) => ({
      ...state,
      resetPinForm: false,
    }),
    hideResetMsg: (state) => ({
      ...state,
      resetMessage: '',
      resetSuccessMsg: '',
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPin.pending, (state) => ({
        ...state,
        resetLoading: true,
        resetMessage: '',
        resetPinForm: false,
      }))
      .addCase(resetPin.fulfilled, (state, { payload }) => {
        let resetMessage;
        let resetSuccessMsg;
        const { erc } = payload;
        if (erc === '25') {
          resetSuccessMsg = msg30;
          resetMessage = '';
        }
        if (erc === '6') {
          resetMessage = msg27;
          resetSuccessMsg = '';
        }
        if (erc === '13') {
          resetMessage = msg25;
          resetSuccessMsg = '';
        }
        if (erc === '177') {
          resetMessage = msg31;
          resetSuccessMsg = '';
        }
        if (erc === '176') {
          resetMessage = msg32;
          resetSuccessMsg = '';
        }
        return {
          ...state,
          resetMessage,
          resetSuccessMsg,
          resetLoading: false,
          resetPinForm: false,
        };
      })

      .addCase(resetPin.rejected, (state, { payload }) => {
        let resetMessage;
        if (payload.erc === '001') {
          resetMessage = tokenText;
        } else { resetMessage = msg29; }
        return {
          ...state,
          resetLoading: false,
          resetMessage,
          resetSuccessMsg: '',
        };
      });
  },
});

export const { showResetPinForm, hideResetPinForm, hideResetMsg } = resetPinSlice.actions;
export default resetPinSlice.reducer;
