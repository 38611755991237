import React from 'react';
import TopBar from './TopBar';
import Body from './Body';

const NavigationPanel = () => (
  <>
    <TopBar />
    <Body />
  </>
);

export default NavigationPanel;
