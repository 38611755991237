const toggleMobileMenu = () => {
  const sidebar = document.getElementById('hide-mobile-sidebar');
  sidebar.classList.toggle('show-mobile-sidebar');

  const primaryClass = document.getElementById('group-btn');
  const sportsMenu = document.getElementById('sub-menu-mobile');
  const tournamentsMenu = document.getElementById('tournaments');

  primaryClass.classList.remove('hide-group-btn');
  sportsMenu.classList.add('hide-sub-menu-mobile');
  if (!tournamentsMenu.classList.contains('tournament')) {
    tournamentsMenu.classList.add('tournament');
  }
};

export default toggleMobileMenu;
