/* eslint jsx-a11y/label-has-associated-control: 0 */
/* eslint  max-len: 0 */
import React, { useState } from 'react';
import '../../../styles/_dashboard.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import translationKeys from 'translations/translationKeys';
import {
  addCombToBetSlip,
  calculateOdds, addCouponsToBetSlip,
  updateStake,
  applyBonus, calculateGains, clearBetSlip,
} from 'redux/configs/betConfigsSlice';
import { logout } from 'redux/login/loginSlice';
import { showResetPinForm } from 'redux/reset-pin/resetPinSlice';
import { GiHamburgerMenu } from 'react-icons/gi';
import { userObj } from 'components/common/requestBody';
import toggleMobileMenu from 'components/helpers/toggleMobileMenu';
import getLanguage from 'components/helpers/getLanguage';
import {
  authParams, getBase64Hash,
} from 'components/helpers/base64AuthParams';
import threeDots from '../../../assets/images/mathys/svg/three-dots-vertical.svg';
import logo from '../../../assets/images/mathys/png/logo.png';
import person from '../../../assets/images/mathys/svg/person.svg';
import plus from '../../../assets/images/mathys/svg/plus-lg.svg';
import baseUrls from '../../../config';

const TopBar = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [message, setMessage] = useState(false);
  const token = JSON.parse(localStorage.getItem('token'));
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const [alertMessage, setAlertMessage] = useState(null);
  const { stake, minStake, bets } = useSelector((store) => store.betSlip);
  const [showCombForm, setShowCombForm] = useState(true);
  const [language, setLanguage] = useState(getLanguage());
  const getCombination = async (body) => {
    const apiBaseUrl = baseUrls.BASE_URL_API;
    const apiVersion2 = baseUrls.API_VERSION2;
    const api = '/ws/sport/combination/info';
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    const headers = {
      'Content-Type': 'application/json; charset=UTF-8',
      'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${api}${JSON.stringify(body)}`)}`,
      Authorization: token ? `Bearer ${token}` : '',
    };
    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    };

    try {
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        throw new Error('Response not OK');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  };
  const loadCoupon = async (coupon) => {
    const apiBaseUrl = baseUrls.BASE_URL_API;
    const apiVersion2 = baseUrls.API_VERSION2;
    const api = `/coupon/${coupon}?vers=${userObj.ver}`;
    const sigParam = `/coupon/${coupon}vers=${userObj.ver}`;
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    const headers = {
      'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${sigParam}`)}`,
      Authorization: token ? `Bearer ${token}` : '',
    };

    try {
      const response = await fetch(url, { headers });
      if (!response.ok) {
        throw new Error('Response not OK');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  };
  const {
    msg41, msg82, msg42, msg80, msg81, msg83, msg143,
  } = translationKeys;

  const clearMessage = () => {
    setTimeout(() => {
      setMessage(false);
      setAlertMessage(null);
    }, 3000);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const pickValue = e.target.elements.combinaison.value;
    const pickRegex = /^\d+\*[^*]+(?:\*\d+){0,2}$/;
    if (!pickRegex.test(pickValue)) {
      setMessage(true);
      setAlertMessage(t(msg41));
      clearMessage();
      return;
    }
    getCombination({
      channel: userObj.chl,
      pick: pickValue,
      language,
    }).then((match) => {
      const data = match && match.data;
      const msg = match && match.msg;
      const matchFound = data && bets.some((item) => item.uniqueId === data.id + data.outcome[0].name + data.outcome[0].market.name);
      if (matchFound) {
        setMessage(true);
        setAlertMessage(t(msg42));
        clearMessage();
      } else if (msg) {
        setMessage(true);
        setAlertMessage(t(msg));
        clearMessage();
      } else {
        dispatch(addCombToBetSlip({
          match: data && data,
          odds: data && data.outcome[0].odds,
          team: data && data.outcome[0].name,
          market: data && data.outcome[0].market.name,
          matchId: data && data.id,
          id: data && data.outcome[0].id,
          uniqueId: data && data.id + data.outcome[0].name + data.outcome[0].market.name,
          matchNumber: data && data.number,
          pick: data && `${data.number}*${data.outcome[0].market.id}*${data.outcome[0].outcome_id}`,
        }));
        dispatch(updateStake(stake === 0 ? minStake : stake));
        dispatch(calculateOdds());
        dispatch(calculateGains());
        dispatch(applyBonus());
        setMessage(false);
        setAlertMessage(null);
      }
    });
  };
  const handleCouponSubmit = (e) => {
    e.preventDefault();
    const couponValue = e.target.elements.coupon.value;
    if (!couponValue) {
      setMessage(true);
      setAlertMessage(t(msg143));
      clearMessage();
      return;
    }
    loadCoupon(couponValue).then((coup) => {
      const arr = [];
      let matchFound;
      const msg = coup && coup.msg;
      const data = coup && coup.data;
      const betChoices = (data && data.betChoices) || [];
      const bet = (data && data.bet);
      if (betChoices.length > 0) {
        betChoices.map((item) => {
          const { match, propositions } = item;
          return propositions.map((p) => {
            const bet = {
              match,
              odds: p.odds,
              team: p.name,
              market: p.market.name,
              matchId: match.id,
              id: p.id,
              uniqueId: match.id + p.name + p.market.name,
              matchNumber: match.number,
              pick: `${match.number}*${p.market.id}*${p.outcome_id}`,
            };
            matchFound = bets.some((item) => item.uniqueId === bet.uniqueId);
            arr.push(bet);
            return bet;
          });
        });
      }
      if (matchFound) {
        setMessage(true);
        setAlertMessage(t(msg42));
        clearMessage();
      } else if (msg) {
        setMessage(true);
        setAlertMessage(t(msg));
        clearMessage();
      } else {
        dispatch(addCouponsToBetSlip(arr));
        dispatch(updateStake(bet && bet.amt));
        dispatch(calculateOdds());
        dispatch(calculateGains());
        dispatch(applyBonus());
        setMessage(false);
        setAlertMessage(null);
      }
    });
  };
  const toggleForm = (e) => {
    setMessage(false);
    setAlertMessage(null);
    dispatch(clearBetSlip());
    if (e.target.value === 'combinaison') {
      setShowCombForm(true);
    } else {
      setShowCombForm(false);
    }
  };
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };
  const handleLanguageChange = (e) => {
    changeLanguage(e.target.value);
    localStorage.setItem('lang', e.target.value);
  };
  const userLanguage = navigator.language.split('-')[0];
  return (
    <>
      <div className="header">
        <nav className="navbar navbar-expand-md">
          <div className="container-fluid">
            <button
              type="button"
              className="btn btn-link btn-home d-block d-md-none"
              onClick={() => toggleMobileMenu()}
            >
              <GiHamburgerMenu />
            </button>
            <a className="navbar-brand m-0" href="/">
              <img src={logo} alt="" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <img src={threeDots} alt="" />
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mb-2 mb-lg-0">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src={person} alt="" />
                    Agent
                    {' '}
                    {userInfo && userInfo.agentId}
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => {
                          dispatch(logout());
                          localStorage.setItem('lang', userLanguage);
                          window.location.reload();
                        }}
                      >
                        {t(msg80)}
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={() => {
                          dispatch(showResetPinForm());
                        }}
                      >
                        {t(msg81)}
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
              <select
                className="form-select"
                value={language}
                onChange={handleLanguageChange}
              >
                <option value="fr">FR</option>
                <option value="en">EN</option>
              </select>
              <select
                className="form-select"
                id="select-comb"
                onChange={toggleForm}
                defaultValue="combinaison"
              >
                <option value="combinaison">{t(msg82)}</option>
                <option value="code-coupon">{t(msg83)}</option>
              </select>
              {showCombForm ? (
                <form
                  className="top-bar-form d-flex"
                  onSubmit={handleSubmit}
                >
                  <label htmlFor="combinaison">
                    <input
                      className="form-control me-2"
                      placeholder={t(msg82)}
                      defaultValue=""
                      name="combinaison"
                      id="combinaison"
                      type="text"
                    />
                  </label>
                  <button
                    className="btn btn-secondary"
                    type="submit"
                  >
                    <img src={plus} alt="" />
                  </button>
                </form>
              ) : (
                <form
                  className="top-bar-form d-flex"
                  onSubmit={handleCouponSubmit}
                >
                  <label htmlFor="coupon">
                    <input
                      className="form-control me-2"
                      defaultValue=""
                      placeholder="Coupon"
                      name="coupon"
                      id="coupon"
                      type="text"
                    />
                  </label>
                  <button
                    className="btn btn-secondary"
                    type="submit"
                  >
                    <img src={plus} alt="" />
                  </button>
                </form>
              )}

            </div>
          </div>
        </nav>
        <div className="element-mobile bg-light">
          {showCombForm ? (
            <form
              className="top-bar-form d-flex"
              onSubmit={handleSubmit}
            >
              <label htmlFor="combinaison">
                <input
                  className="form-control me-2"
                  type="text"
                  placeholder={t(msg82)}
                  name="combinaison"
                />
              </label>
              <button className="btn btn-secondary" type="submit">
                <img src={plus} alt="" />
              </button>
            </form>
          ) : (
            <form
              className="top-bar-form d-flex"
              onSubmit={handleCouponSubmit}
            >
              <label htmlFor="coupon">
                <input
                  className="form-control me-2"
                  placeholder="Coupon"
                  name="coupon"
                  id="coupon"
                  type="text"
                />
              </label>
              <button
                className="btn btn-secondary"
                type="submit"
              >
                <img src={plus} alt="" />
              </button>
            </form>
          )}

        </div>
      </div>
      {message && (
      <div className="line comb">
        <div className="div-msg comb-msg">
          <span>Info!</span>
          <p>{alertMessage}</p>
        </div>
      </div>
      )}
    </>
  );
};

export default TopBar;
