import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import { userObj } from 'components/common/requestBody';
import getLanguage from 'components/helpers/getLanguage';
import translationKeys from 'translations/translationKeys';
import axios from 'axios';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});

const { msg29 } = translationKeys;

export const getAllSports = createAsyncThunk(
  'sports/getAllSports',
  async (thunkAPI) => {
    const lang = getLanguage();
    const api = `/sport/0/sports?channel_id=${userObj.chl}&lang=${lang}`;
    const signatureParam = `/sport/0/sportschannel_id=${userObj.chl}&lang=${lang}`;
    const url = `/${apiVersion2}${api}`;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}`)}`,
        },
      };
      const resp = await client.get(url, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(msg29);
    }
  },
);

const initialState = {

  allSports: [],
  isLoading: true,
  error: '',
  sportSelected: null,
};

const allSportsSlice = createSlice({
  name: 'allSports',
  initialState,
  reducers: {
    selectSport: (state, { payload }) => {
      const sportSelected = payload;
      const updatedSports = state.allSports.map((sport) => {
        if (sport.id === payload.id) {
          localStorage.setItem('sportSelected', JSON.stringify(sport));
          return { ...sport, selected: true };
        }
        return sport;
      });
      return {
        ...state,
        allSports: updatedSports,
        sportSelected,
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllSports.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(getAllSports.fulfilled, (state, action) => {
        const allSportsWithPath = action.payload.map((item) => {
          const formattedName = item.name.toLowerCase().replace(/\s/g, '');
          return {
            ...item,
            path: formattedName,
          };
        });
        return {
          ...state,
          allSports: allSportsWithPath,
          isLoading: false,
        };
      })

      .addCase(getAllSports.rejected, (state) => ({
        ...state,
        isLoading: false,
        error: msg29,
      }));
  },
});

export const {
  selectSport,
} = allSportsSlice.actions;

export default allSportsSlice.reducer;
