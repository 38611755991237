/* eslint  max-len: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import getLanguage from 'components/helpers/getLanguage';
import translationKeys from 'translations/translationKeys';
import tokenText from 'components/helpers/tokenText';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const { msg29 } = translationKeys;
export const withdrawWithCode = createAsyncThunk(
  'Withdrawals/withdrawWithCode',
  async (obj, thunkAPI) => {
    const lang = getLanguage();
    const token = JSON.parse(localStorage.getItem('token'));
    const requestParams = `lang=${lang}&cac=${obj.cac}&wcd=${obj.wcd}&erf=${obj.erf}&phn=${obj.agentPhone}&uid=${obj.agentId}&str_Pin=${obj.agentPwd}&Ig_Channel_ID=${obj.chl}&ime=${obj.imei}&imei=${obj.imei}`;
    const api = `/ser_withdrawal_with_code?${requestParams}`;
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    const signatureParam = `/ser_withdrawal_with_code${requestParams}`;
    try {
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
        'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}${JSON.stringify({})}`)}`,
        Authorization: token ? `Bearer ${token}` : '',
      };
      const resp = await client.post(url, {}, { headers });
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.response.data.message || 'An error occurred',
        erc: error.response.data.erc,
      });
    }
  },
);
const initialState = {
  withdrawWithCode: null,
  withdrawWithCodeData: [],
  withdrawWithCodeLoading: false,
  failedwithdrawWithCode: false,
  withdrawWithCodeMsg: '',
  canPrint: false,
  withdrawed: false,
  errorWithdrawWithCode: '',
};

const withdrawWithCodeSlice = createSlice({
  name: 'withdraw-with-code',
  initialState,
  reducers: {
    hideWithdrawWithCodeBox: (state) => ({
      ...state,
      withdrawed: false,
      canPrint: false,
    }),
  },

  extraReducers: (builder) => {
    builder
      .addCase(withdrawWithCode.pending, (state) => ({
        ...state,
        withdrawWithCodeLoading: true,
      }))
      .addCase(withdrawWithCode.fulfilled, (state, { payload }) => {
        let withdrawWithCodeData;
        let canPrint;
        if (payload.data && payload.data.length > 0) {
          canPrint = true;
          withdrawWithCodeData = payload.data;
        }
        return {
          ...state,
          canPrint,
          withdrawWithCodeData,
          withdrawWithCodeLoading: false,
        };
      })

      .addCase(withdrawWithCode.rejected, (state, { payload }) => {
        let withdrawWithCodeMsg;
        if (payload && payload.erc && payload.erc === '001') {
          withdrawWithCodeMsg = tokenText;
        } else { withdrawWithCodeMsg = msg29; }
        return {
          ...state,
          withdrawWithCodeLoading: false,
          failedwithdrawWithCode: false,
          withdrawWithCodeMsg,
        };
      });
  },
});

export const {
  hideWithdrawWithCodeBox,
} = withdrawWithCodeSlice.actions;

export default withdrawWithCodeSlice.reducer;
