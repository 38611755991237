const bonusOptions = {
  min_stk: 300,
  name: 'System Bonus',
  min_evt: 5,
  max_stk: 3000000,
  detail: [
    {
      evt_num: 5,
      percent: 10,
    },
    {
      evt_num: 6,
      percent: 15,
    },
    {
      evt_num: 7,
      percent: 20,
    },
    {
      evt_num: 8,
      percent: 25,
    },
    {
      evt_num: 9,
      percent: 30,
    },
    {
      evt_num: 10,
      percent: 35,
    },
    {
      evt_num: 11,
      percent: 40,
    },
    {
      evt_num: 12,
      percent: 45,
    },
    {
      evt_num: 13,
      percent: 50,
    },
    {
      evt_num: 14,
      percent: 55,
    },
    {
      evt_num: 15,
      percent: 60,
    },
    {
      evt_num: 16,
      percent: 65,
    },
    {
      evt_num: 17,
      percent: 65,
    },
    {
      evt_num: 18,
      percent: 65,
    },
    {
      evt_num: 19,
      percent: 65,
    },
    {
      evt_num: 20,
      percent: 65,
    },
  ],
  max_evt: 20,
  max_pw: 20000000,
  min_odd: 1.5,
  deduct_stake: true,
  desc: 'Basic Bonus Configuration',
};

export default bonusOptions;
