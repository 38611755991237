/* eslint  jsx-a11y/click-events-have-key-events: 0 */
/* eslint  jsx-a11y/no-static-element-interactions: 0 */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { userObj } from 'components/common/requestBody';
import { resetPin, hideResetPinForm } from 'redux/reset-pin/resetPinSlice';
import translationKeys from 'translations/translationKeys';

const ResetPinComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const [isShown, setIsSHown] = useState(false);
  const {
    msg7, msg99, msg8, msg100, msg136, msg137, msg101, msg102,
    msg103, msg104, msg55,
  } = translationKeys;
  const formik = useFormik({
    initialValues: {
      pin: '',
      newPin: '',
      confirmPin: '',
      phone: userInfo && userInfo.agentPhone,
      matricule: userInfo && userInfo.agentId,
      channel: userObj.chl,
      strFrom: userInfo && userInfo.agentPhone,
      ime: userObj.imei,
    },
    validationSchema: Yup.object({
      pin: Yup.string().required(t(msg7)),
      newPin: Yup.string().required(t(msg99)).notOneOf([Yup.ref('pin')], t(msg100)),
      confirmPin: Yup.string()
        .required(t(msg136))
        .oneOf([Yup.ref('newPin'), null], t(msg137)),
    }),
    onSubmit: (values) => {
      dispatch(resetPin(values));
    },
  });

  const togglePassword = () => {
    setIsSHown((isShown) => !isShown);
  };

  return (
    <div className="modalContainer">
      <div className="login">
        <div
          className="content "
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              dispatch(hideResetPinForm());
            }
          }}
        >
          <div
            className="panel bg-white reset-pin-form"
            onClick={(e) => e.stopPropagation()}
          >
            <h1>{t(msg101)}</h1>
            <form onSubmit={formik.handleSubmit}>
              <div className="ctn-input mb-1">
                <label htmlFor="pin" className="form-label">
                  {t(msg102)}
                  <input
                    type="text"
                    className="form-control input_image"
                    id="pin"
                    placeholder={t(msg102)}
                    aria-describedby="pin"
                    value={formik.values.pin}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </label>
                {formik.touched.pin && formik.errors.pin ? (
                  <div id="pin" className="form-text">{formik.errors.pin}</div>

                ) : null}
              </div>
              <div className="ctn-input mb-1">
                <label htmlFor="newPin" className="form-label">
                  {t(msg103)}
                  <input
                    type={isShown ? 'text' : 'password'}
                    className="form-control input_image"
                    id="newPin"
                    placeholder={t(msg103)}
                    name="newPin"
                    value={formik.values.newPin}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </label>
                {formik.touched.newPin && formik.errors.newPin ? (
                  <div id="anewPin" className="form-text">{formik.errors.newPin}</div>

                ) : null}
              </div>
              <div className="ctn-input mb-1">
                <label htmlFor="confirmPin" className="form-label">
                  {t(msg104)}
                  <input
                    type={isShown ? 'text' : 'password'}
                    className="form-control input_image"
                    id="confirmPin"
                    placeholder={t(msg104)}
                    name="confirmPin"
                    value={formik.values.confirmPin}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.confirmPin && formik.errors.confirmPin && <div className="form-text">{formik.errors.confirmPin}</div>}
                </label>
              </div>
              <div className="checkbox-container">
                <label htmlFor="checkbox" id="show-password">
                  <span>{t(msg8)}</span>
                  <input
                    type="checkbox"
                    id="checkbox"
                    checked={isShown}
                    onChange={togglePassword}
                  />
                </label>
              </div>
              <button
                type="submit"
                className="btn btn-secondary"
              >
                <span>{t(msg55)}</span>
              </button>

            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPinComponent;
