import React from 'react';
import { useTranslation } from 'react-i18next';
import translationKeys from 'translations/translationKeys';

const Loading = () => {
  const { msg22 } = translationKeys;
  const { t } = useTranslation();
  return (
    <div
      className="loading"
      style={{
        width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
    >
      <span className="spinner-border spinner-border-sm" />
      { ' ' }
      {t(msg22)}
    </div>
  );
};

export default Loading;
