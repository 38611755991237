/* eslint  max-len: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { authParams, getBase64Hash, parseParams } from 'components/helpers/base64AuthParams';
import { getDeviceImei } from 'components/helpers/printTicketData';
import translationKeys from 'translations/translationKeys';
import getLanguage from 'components/helpers/getLanguage';
import axios from 'axios';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const {
  msg29, msg28, msg23, msg24, msg26, msg27, msg25, msg148,
} = translationKeys;
const api = '/agent-servicev2/ser_agent_auth';
export const login = createAsyncThunk(
  'session/login',
  async (body, thunkAPI) => {
    const imei = getDeviceImei();
    const lang = getLanguage();
    const updatedBody = {
      ...body,
      lang,
      ime: (imei && (imei !== undefined || imei !== null)) ? imei : 'OLD_VERSION',
    };
    const stringParams = parseParams(updatedBody);
    const url = `/${apiVersion2}${api}${stringParams}`;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${api}${stringParams.split('?')[1]}`)}`,
        },
      };
      const resp = await client.get(url, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const initialState = {
  isLoading: false,
  login: null,
  useInfo: {},
  agentId: '',
  agentPwd: '',
  agentPhone: '',
  token: '',
  success: false,
  isLoggedIn: false,
  loginMessage: '',
  resetPinMessage: '',
  error: '',
};

const loginSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    logout: (state) => {
      sessionStorage.removeItem('userInfo');
      sessionStorage.removeItem('refreshToken');
      localStorage.clear();
      return {
        ...state,
        isLoggedIn: false,
        isLoading: false,
        success: false,
      };
    },
    hideResetPinModal: (state) => ({
      ...state,
      loginMessage: '',
      resetPinMessage: '',
    }),
  },

  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => ({
        ...state,
        isLoading: true,
        isLoggedIn: false,
        success: false,
        loginMessage: '',
        message: '',
      }))
      .addCase(login.fulfilled, (state, { payload, meta }) => {
        let agentId;
        let agentPhone;
        let success;
        let loginMessage;
        let resetPinMessage;
        let isLoggedIn;
        let token;
        let error;
        const { outData, erc, data } = payload;
        const { arg } = meta;
        if (erc === '1' && !outData.access_token) {
          success = false;
          isLoggedIn = false;
          loginMessage = msg23;
        }
        if (erc === '1' && outData.access_token) {
          sessionStorage.setItem('userInfo', JSON.stringify({
            agentId: data.id,
            agentPwd: arg.str_pin,
            agentPhone: data.phone,
          }));
          localStorage.setItem('expireAt', outData.expire_at);
          localStorage.setItem('token', JSON.stringify(outData.access_token));
          localStorage.setItem('agentId', data.id);
          sessionStorage.setItem('refreshToken', JSON.stringify(outData.refresh_token));
          isLoggedIn = true;
          token = outData.access_token;
          success = true;
          isLoggedIn = true;
          error = '';
          loginMessage = '';
        }
        if (erc === '171') {
          isLoggedIn = false;
          success = false;
          isLoggedIn = false;
          error = '';
          resetPinMessage = msg24;
          loginMessage = '';
          sessionStorage.setItem('tempUserInfo', JSON.stringify(
            {
              phone: payload.phn,
              agentId: arg.str_mat,
            },
          ));
        }
        if (erc === '111') {
          isLoggedIn = false;
          success = false;
          isLoggedIn = false;
          error = '';
          loginMessage = msg25;
        }
        if (erc === '164') {
          isLoggedIn = false;
          success = false;
          error = '';
          loginMessage = msg26;
        }
        if (erc === '6') {
          isLoggedIn = false;
          success = false;
          error = '';
          loginMessage = msg27;
        }
        if (erc === '38') {
          isLoggedIn = false;
          success = false;
          error = '';
          loginMessage = msg28;
        }
        if (payload && payload[0] && payload[0].erc === '0') {
          isLoggedIn = false;
          success = false;
          error = '';
          loginMessage = msg29;
        }
        if (payload && payload[0] && payload[0].erc === '10') {
          isLoggedIn = false;
          success = false;
          error = '';
          loginMessage = msg148;
        }
        return {
          ...state,
          login: payload,
          agentId,
          agentPhone,
          success,
          loginMessage,
          resetPinMessage,
          error,
          isLoggedIn,
          token,
          isLoading: false,
        };
      })

      .addCase(login.rejected, (state) => ({
        ...state,
        isLoading: false,
        isLoggedIn: false,
        success: false,
        loginMessage: msg29,
      }));
  },
});

export const { logout, getPassWord, hideResetPinModal } = loginSlice.actions;

export default loginSlice.reducer;
