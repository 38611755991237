import { createSlice } from '@reduxjs/toolkit';
import { sportsData } from 'homeData';

const initialState = {
  sportList: [],
};

const homeSportsSlice = createSlice({
  name: 'listSports',
  initialState,
  reducers: {
    homeSportsData: (state) => ({
      ...state,
      sportList: sportsData,
    }),
  },
});

export const { homeSportsData } = homeSportsSlice.actions;
export default homeSportsSlice.reducer;
