/* eslint  max-len: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import { userObj } from 'components/common/requestBody';
import translationKeys from 'translations/translationKeys';
import getLanguage from 'components/helpers/getLanguage';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const { msg29 } = translationKeys;
const lang = getLanguage();
const api = `/sport/event/topMatches/filter?markets=1&channel_id=${userObj.chl}&lang=${lang}`;
const signatureParam = `/sport/event/topMatches/filtermarkets=1&channel_id=${userObj.chl}&lang=${lang}`;
const url = `${apiBaseUrl}/${apiVersion2}${api}`;
export const getMeilleursParis = createAsyncThunk(
  'sports/getMeilleursParis',
  async (data, thunkAPI) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}${JSON.stringify(data)}`)}`,
        },
      };
      const resp = await client.post(url, data, config);
      localStorage.setItem('savedData', JSON.stringify(data));
      localStorage.setItem('topMatchesFilter', JSON.stringify(resp.data));
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(msg29);
    }
  },
);

const initialState = {

  meilleursParis: [],
  filters: [],
  allsports: [],
  successTopMatches: false,
  isLoading: false,
  error: '',
};

const meilleursParisSlice = createSlice({
  name: 'meilleursParis',
  initialState,
  reducers: {
    selectItem: (state, { payload }) => {
      const updatedTopMatches = state.allTopMatches.map((match) => ({
        ...match,
        categories: match?.categories?.map((cat) => ({
          ...cat,
          tournaments: cat?.tournaments?.map((t) => ({
            ...t,
            matches: t?.matches?.map((m) => ({
              ...m,
              outcome: m?.outcome?.map((o) => (o.uniqueId === payload ? { ...o, inBetSlip: true } : o)),
            })),
          })),
        })),
      }));

      return {
        ...state,
        meilleursParis: updatedTopMatches,
        isLoading: false,
      };
    },
    unSelectFromTopmatch: (state, { payload }) => {
      const updatedTopMatches = state.allTopMatches.map((match) => ({
        ...match,
        categories: match?.categories?.map((cat) => ({
          ...cat,
          tournaments: cat?.tournaments?.map((t) => ({
            ...t,
            matches: t?.matches?.map((m) => ({
              ...m,
              outcome: m?.outcome?.map((o) => (o.uniqueId === payload ? { ...o, inBetSlip: false } : o)),
            })),
          })),
        })),
      }));

      return {
        ...state,
        meilleursParis: updatedTopMatches,
        isLoading: false,
      };
    },
    setAllToFalse: (state) => {
      const updatedTopMatches = state.allTopMatches.map((match) => ({
        ...match,
        categories: match?.categories?.map((cat) => ({
          ...cat,
          tournaments: cat?.tournaments?.map((t) => ({
            ...t,
            matches: t?.matches?.map((m) => ({
              ...m,
              outcome: m?.outcome?.map((outcome) => ({
                ...outcome,
                inBetSlip: false,
              })),
            })),
          })),
        })),
      }));

      return {
        ...state,
        meilleursParis: updatedTopMatches,
        isLoading: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMeilleursParis.pending, (state) => ({
        ...state,
        isLoading: true,
        meilleursParis: [],
        filters: [],
        allsports: [],
        successTopMatches: false,
        error: '',
      }))
      .addCase(getMeilleursParis.fulfilled, (state, { payload }) => {
        let successTopMatches = false;
        let updatedTopMatches = state.meilleursParis;
        let updatedFilters = state.filters;

        if (payload?.length > 0) {
          successTopMatches = true;
          updatedFilters = [...payload.map((match) => ({
            id: match.id,
            name: match.name,
            count: match.count,
            icon: match.icon,
          })), ...updatedFilters];

          updatedFilters = updatedFilters.filter((filter, index, array) => {
            const ids = array.map((item) => item.id);
            const filterId = filter.id;

            return ids.indexOf(filterId) === index;
          });

          updatedTopMatches = [...payload.map((match) => ({
            ...match,
            categories: match?.categories?.map((cat) => ({
              ...cat,
              tournaments: cat?.tournaments?.map((t) => ({
                ...t,
                matches: t?.matches?.map((m) => ({
                  ...m,
                  outcome: m?.outcome?.map((outcome) => ({
                    ...outcome,
                    inBetSlip: false,
                    matchId: m?.id,
                    icon: match?.icon,
                    uniqueId: m?.id + outcome?.name + outcome?.market.name,
                    count: m?.count,
                  })),
                })),
              })),
            })),
          })), ...updatedTopMatches];

          updatedTopMatches = updatedTopMatches.filter((match, index, array) => {
            const ids = array.map((item) => item.id);
            const matchId = match.id;

            return ids.indexOf(matchId) === index;
          });
        }
        return {
          ...state,
          meilleursParis: updatedTopMatches,
          successTopMatches,
          allsports: payload,
          isLoading: false,
          filters: updatedFilters,
        };
      })
      .addCase(getMeilleursParis.rejected, (state) => ({
        ...state,
        isLoading: false,
        successTopMatches: false,
        error: msg29,
      }));
  },
});

export const {
  selectItem, unSelectFromTopmatch,
  setAllToFalse,
} = meilleursParisSlice.actions;

export default meilleursParisSlice.reducer;
