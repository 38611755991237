/* eslint  max-len: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash, token } from 'components/helpers/base64AuthParams';
import getLanguage from 'components/helpers/getLanguage';
import translationKeys from 'translations/translationKeys';
import tokenText from 'components/helpers/tokenText';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const {
  msg29, msg113, msg114, msg115, msg138, msg116, msg117, msg118, msg119, msg131,
} = translationKeys;
export const bonRecharge = createAsyncThunk(
  'Recharge/bonRecharge',
  async (obj, thunkAPI) => {
    const lang = getLanguage();
    const requestParams = `lang=${lang}&uid=${obj.agentId}&ref=${obj.ref}&iref=${obj.iref}&chl=${obj.chl}&ime=${obj.imei}&vers=${obj.ver}&imei=${obj.imei}&pwd=${obj.agentPwd}`;
    const api = `/ser_vente_coupon?${requestParams}`;
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    const signatureParam = `/ser_vente_coupon${requestParams}`;
    try {
      const headers = {
        'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}${JSON.stringify({})}`)}`,
        Authorization: token ? `Bearer ${token}` : '',
      };
      const resp = await client.post(url, {}, { headers });
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.response.data.message || 'An error occurred',
        erc: error.response.data.erc,
      });
    }
  },
);
const transformArray = (originalArray) => {
  const transformedArray = [
    'Recharge  Coupon',
    '--------------------------------',
    `CODE RECHARGE  : ${originalArray[0].str_Code_Recharge}`,
    '--------------------------------',
    `REF        : ${originalArray[0].str_REF_CODE}`,
    '--------------------------------',
    `MONTANT    : FC ${originalArray[0].int_Amount}`,
    '--------------------------------',
    `DATE       : ${originalArray[0].dt_Created}`,
    '--------------------------------',
  ];
  return transformedArray;
};

const initialState = {
  bonRechargeData: [],
  isBonRechargeLoading: false,
  bonRechargeSuccess: false,
  failedBonRecharge: false,
  showBonRechargeConfirm: false,
  bonValue: 0,
  errorBonRecharge: '',
};

const bonDepositSlice = createSlice({
  name: 'bon-deposit',
  initialState,
  reducers: {
    showBonRechargeConfirmBox: (state) => ({
      ...state,
      showBonRechargeConfirm: true,
      errorBonRecharge: '',
    }),
    hideBonRechargeConfirmBox: (state) => ({
      ...state,
      showBonRechargeConfirm: false,
      bonRechargeSuccess: false,
      isBonRechargeLoading: false,
      errorBonRecharge: '',
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(bonRecharge.pending, (state) => ({
        ...state,
        isBonRechargeLoading: true,
      }))
      .addCase(bonRecharge.fulfilled, (state, { payload }) => {
        let showBonRechargeConfirm;
        let bonRechargeSuccess;
        let bonRechargeData;
        let errorBonRecharge;
        if (payload && payload[0] && payload[0].ERROR_CODE === '1' && payload[0].str_RAISON === '01') {
          bonRechargeSuccess = true;
          showBonRechargeConfirm = false;
          errorBonRecharge = '';
          bonRechargeData = transformArray(payload);
        } if (payload && payload[0] && payload[0].ERROR_CODE === '0' && payload[0].str_RAISON === '07') {
          errorBonRecharge = msg113;
          bonRechargeSuccess = false;
        } if (payload && payload[0] && payload[0].ERROR_CODE === '0' && payload[0].str_RAISON === '160') {
          errorBonRecharge = msg138;
          bonRechargeSuccess = false;
        } if (payload && payload[0] && payload[0].ERROR_CODE === '0' && payload[0].str_RAISON === '06') {
          errorBonRecharge = msg114;
          bonRechargeSuccess = false;
        } if (payload && payload[0] && payload[0].ERROR_CODE === '0' && payload[0].str_RAISON === '05') {
          errorBonRecharge = msg115;
          bonRechargeSuccess = false;
        } if (payload && payload[0] && payload[0].ERROR_CODE === '0' && payload[0].str_RAISON === '04') {
          errorBonRecharge = msg116;
          bonRechargeSuccess = false;
        } if (payload && payload[0] && payload[0].ERROR_CODE === '0' && payload[0].str_RAISON === '08') {
          errorBonRecharge = msg29;
          bonRechargeSuccess = false;
        } if (payload && payload[0] && payload[0].ERROR_CODE === '0' && payload[0].str_RAISON === '02') {
          errorBonRecharge = msg117;
          bonRechargeSuccess = false;
        } if (payload && payload[0] && payload[0].ERROR_CODE === '0' && payload[0].str_RAISON === '03') {
          errorBonRecharge = msg118;
          bonRechargeSuccess = false;
        } if (payload && payload[0] && payload[0].ERROR_CODE === '0' && payload[0].str_RAISON === '9') {
          errorBonRecharge = msg29;
          bonRechargeSuccess = false;
        } if (payload && payload[0] && payload[0].ERROR_CODE === '0' && payload[0].str_RAISON === '45') {
          errorBonRecharge = msg119;
          bonRechargeSuccess = false;
        }
        if (payload && payload[0] && payload[0].ERROR_CODE === '0' && payload[0].str_RAISON === '168') {
          errorBonRecharge = msg131;
          bonRechargeSuccess = false;
        }

        return {
          ...state,
          bonRechargeData,
          isBonRechargeLoading: false,
          showBonRechargeConfirm,
          errorBonRecharge,
          bonRechargeSuccess,
          failedBonRecharge: false,
        };
      })
      .addCase(bonRecharge.rejected, (state, { payload }) => {
        let errorBonRecharge;
        if (payload && payload.erc && payload.erc === '001') {
          errorBonRecharge = tokenText;
        } else { errorBonRecharge = msg29; }
        return {
          ...state,
          isBonRechargeLoading: false,
          errorBonRecharge,
        };
      });
  },
});

export const {
  showBonRechargeConfirmBox, hideBonRechargeConfirmBox,
} = bonDepositSlice.actions;

export default bonDepositSlice.reducer;
