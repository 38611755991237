import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import { currencyOpts } from 'components/helpers/currencyOptions';
import getLanguage from 'components/helpers/getLanguage';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});

export const getCurrencyConfigs = createAsyncThunk(
  'configs/getCurrencyConfigs',
  async (thunkAPI) => {
    const lang = getLanguage();
    const api = `/system/configs/currency?lang=${lang}`;
    const signatureParam = `/system/configs/currencylang=${lang}`;
    const url = `/${apiVersion2}${api}`;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}`)}`,
        },
      };
      const resp = await client.get(url, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const initialState = {
  currencyConfigs: currencyOpts,
  currencyConfigsSuccess: false,
  isLoading: false,
  error: '',
};

const currencyConfigsSlice = createSlice({
  name: 'currencyConfigs',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getCurrencyConfigs.pending, (state) => ({
        ...state,
        isLoading: true,
        currencyConfigsSuccess: false,
      }))
      .addCase(getCurrencyConfigs.fulfilled, (state, { payload }) => ({
        ...state,
        currencyConfigs: (payload && payload.msg === 'success' && JSON.parse(payload.data)) || currencyOpts,
        isLoading: false,
        currencyConfigsSuccess: true,
      }))

      .addCase(getCurrencyConfigs.rejected, (state) => ({
        ...state,
        isLoading: false,
        currencyConfigsSuccess: false,
        error: 'Something went wrong',
      }));
  },
});

export default currencyConfigsSlice.reducer;
