/* eslint  max-len: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import tokenText from 'components/helpers/tokenText';
import translationKeys from 'translations/translationKeys';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const { msg29 } = translationKeys;
export const getBalance = createAsyncThunk(
  'solde/getBalance',
  async (obj, thunkAPI) => {
    const requestParams = `uid=${obj.uuid}&date=${obj.date}&balanceType=${obj.balanceType}&mode=${obj.mode}&ime=${obj.ime}&imei=${obj.imei}&vers=${obj.ver}&pwd=${obj.agentPwd}`;
    const api = `/ser_get_solde_soccer?${requestParams}`;
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    const signatureParam = `/ser_get_solde_soccer${requestParams}`;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}`)}`,
        },
      };

      const resp = await client.get(url, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.response.data.message || 'An error occurred',
        erc: error.response.data.erc,
      });
    }
  },
);

const initialState = {
  balance: [],
  isbalanceLoading: false,
  successGetBalance: false,
  failedGetBalance: false,
  showBalance: false,
  getBalanceMsg: '',
  errorGetBalance: '',
};

const getBalanceSlice = createSlice({
  name: 'get-balance',
  initialState,
  reducers: {

    showBalanceBox: (state) => ({
      ...state,
      showBalance: true,
      errorGetBalance: '',
    }),
    hideshowBalanceBox: (state) => ({
      ...state,
      showBalance: false,
      successGetBalance: false,
      getBalanceMsg: '',
      errorGetBalance: '',
    }),
  },

  extraReducers: (builder) => {
    builder
      .addCase(getBalance.pending, (state) => ({
        ...state,
        isbalanceLoading: true,
      }))
      .addCase(getBalance.fulfilled, (state, { payload }) => {
        let balance;
        let successGetBalance;
        if (payload.data.length) {
          balance = payload.data;
          successGetBalance = true;
        }
        return {
          ...state,
          balance,
          successGetBalance,
          isbalanceLoading: false,
          getBalanceMsg: '',
        };
      })

      .addCase(getBalance.rejected, (state, { payload }) => {
        let errorGetBalance;
        if (payload && payload.erc && payload.erc === '001') {
          errorGetBalance = tokenText;
        } else { errorGetBalance = msg29; }
        return {
          ...state,
          isbalanceLoading: false,
          successGetBalance: false,
          errorGetBalance,
        };
      });
  },
});

export const {
  showBalanceBox, hideshowBalanceBox,
} = getBalanceSlice.actions;

export default getBalanceSlice.reducer;
