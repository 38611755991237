import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import getLanguage from 'components/helpers/getLanguage';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});

export const getSystemConfigs = createAsyncThunk(
  'configs/getSystemConfigs',
  async (thunkAPI) => {
    const lang = getLanguage();
    const api = `/system/configs?lang=${lang}`;
    const signatureParam = `/system/configslang=${lang}`;
    const url = `/${apiVersion2}${api}`;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}`)}`,
        },
      };
      const resp = await client.get(url, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const initialState = {

  systemConfigs: [],
  isLoading: true,
  error: '',
};

const systemConfigsSlice = createSlice({
  name: 'systemConfigs',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getSystemConfigs.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(getSystemConfigs.fulfilled, (state, action) => ({
        ...state,
        systemConfigs: action.payload,
        isLoading: false,
      }))

      .addCase(getSystemConfigs.rejected, (state) => ({
        ...state,
        isLoading: false,
        error: 'Something went wrong',
      }));
  },
});

export default systemConfigsSlice.reducer;
