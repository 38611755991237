import React from 'react';
import NavigationPanel from './navigationPanel/NavigationPanel';

const Dashboard = () => (
  <>
    <NavigationPanel />
  </>
);

export default Dashboard;
