/* eslint  no-restricted-syntax: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import { userObj } from 'components/common/requestBody';
import translationKeys from 'translations/translationKeys';
import getLanguage from 'components/helpers/getLanguage';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});

const { msg29 } = translationKeys;

export const getTomatchesMkts = createAsyncThunk(
  'sports/getTomatchesMkts',
  async (thunkAPI) => {
    const lang = getLanguage();
    const api = `/sport/markets?channel_id=${userObj.chl}&lang=${lang}`;
    const signatureParam = `/sport/marketschannel_id=${userObj.chl}&lang=${lang}`;
    const url = `/${apiVersion2}${api}`;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}`)}`,
        },
      };

      const resp = await client.get(url, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(msg29);
    }
  },
);

const initialState = {
  topMatchesMkts: {},
  successTopMatchesMkts: false,
  cleanedMarkets: {},
  isLoading: true,
  error: '',
};

const topmatchesMktsSlice = createSlice({
  name: 'topMatches markets',
  initialState,
  reducers: {
    selectMarket: (state, { payload }) => {
      const market = payload;
      localStorage.setItem('marketSelected', JSON.stringify(market));
      return {
        state,
        market,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getTomatchesMkts.pending, (state) => ({
      ...state,
      isLoading: true,
    }));

    builder.addCase(getTomatchesMkts.fulfilled, (state, { payload }) => {
      let topMatchesMkts;
      let successTopMatchesMkts = false;
      const cleanedMarkets = {};
      if (payload !== undefined && payload.erc !== '1') {
        topMatchesMkts = payload;
        successTopMatchesMkts = true;
        const keys = Object.keys(payload);
        for (const key of keys) {
          const markets = payload[key];
          if (markets instanceof Array) {
            markets.forEach((market) => {
              const jsonMarket = JSON.parse(market);
              const marketOutcomes = jsonMarket.outcomes;
              if (marketOutcomes instanceof Array) {
                marketOutcomes.forEach((outcome) => {
                  if (cleanedMarkets[`${jsonMarket.marketId}-${outcome.outComeId}`] === undefined) {
                    cleanedMarkets[`${jsonMarket.marketId}-${outcome.outComeId}`] = outcome.display;
                  }
                });
              }
            });
          }
        }
      }
      return {
        ...state,
        topMatchesMkts: { ...state.topMatchesMkts, ...topMatchesMkts },
        cleanedMarkets: { ...state.cleanedMarkets, ...cleanedMarkets },
        isLoading: false,
        successTopMatchesMkts,
      };
    });

    builder.addCase(getTomatchesMkts.rejected, (state) => ({
      ...state,
      isLoading: false,
      successTopMatchesMkts: false,
      error: msg29,
    }));
  },
});

export const { selectMarket } = topmatchesMktsSlice.actions;

export default topmatchesMktsSlice.reducer;
