/* eslint  no-restricted-syntax: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API_PRINT;
const client = axios.create({
  baseURL: apiBaseUrl,
});

export const printTicket = createAsyncThunk(
  'Tickets/print ticket',
  async (ticket, thunkAPI) => {
    // const encodedData = encodeURIComponent(JSON.stringify(ticket));
    // const api = `/print?ticket=${encodedData}`;
    const api = '/print-esc-pos';
    try {
      // const resp = await client.get(api);
      const resp = await client.post(api, { lines: ticket }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return resp.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue({
        printData: null,
        isPrintLoading: false,
        printError: true,
      });
    }
  },
);

const initialState = {
  printData: null,
  isPrintLoading: false,
  printError: false,
};

const printTicketSlice = createSlice({
  name: 'print ticket',
  initialState,
  reducers: {
    refreshPrinting: (state) => ({ ...state, ...initialState }),
  },

  extraReducers: (builder) => {
    builder
      .addCase(printTicket.pending, (state) => ({
        ...state,
        isPrintLoading: true,
        printError: false,
      }))
      .addCase(printTicket.fulfilled, (state, { payload }) => ({
        ...state,
        printData: payload,
        isPrintLoading: false,
        printError: false,

      }))

      .addCase(printTicket.rejected, (state) => ({
        ...state,
        isPrintLoading: false,
        printError: true,
      }));
  },
});

export const { refreshPrinting } = printTicketSlice.actions;

export default printTicketSlice.reducer;
