const currencyOpts = {
  strCode: 'FC',
  bDefault: true,
  dbValue: 1,
  strGroupingSeparator: ',',
  strDecimalSeparator: '.',
  strDisplayFormat: '#,###,###,##0.0',
  bCodeAppendedEnd: false,
  bAddCodeValueSeparator: true,
  strCodeValueSeparator: ' ',
  intFractionsCount: 2,
};

const formatCurrency = (amount, currencyOpts) => {
  const {
    strCode,
    bDefault,
    strGroupingSeparator,
    strDecimalSeparator,
    bCodeAppendedEnd,
    bAddCodeValueSeparator,
    strCodeValueSeparator,
    intFractionsCount,
  } = currencyOpts;

  // Check if the currency code is valid
  if (!strCode || typeof strCode !== 'string') {
    throw new Error('Invalid currency code');
  }

  // Ensure the amount is a number
  if (typeof amount !== 'number') {
    throw new Error('Invalid amount');
  }

  // Format the amount
  const formatAmount = (amount) => {
    // Ensure the amount is a number
    if (typeof amount !== 'number') {
      throw new Error('Invalid amount');
    } else {
      // Split the amount into integer and decimal parts
      const [integerPart, decimalPart] = amount.toString().split('.');

      // Format integer part with grouping separators
      const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, strGroupingSeparator);

      // Handle decimal part based on intFractionsCount
      let formattedDecimalPart = '';
      if (intFractionsCount > 0) {
        // If no decimal part set it to '0
        const decimal = decimalPart ?? '0';
        if (Number(1 + decimal) < (10 ** (intFractionsCount + 1))) {
          formattedDecimalPart = strDecimalSeparator + decimal.padEnd(intFractionsCount, '0');
        } else {
          const power = decimal.length - intFractionsCount;
          const truncatedDecimal = Math.round(Number(1 + decimal) / 10 ** power);
          formattedDecimalPart = strDecimalSeparator + truncatedDecimal.toString().substring(1).padEnd(intFractionsCount, '0');
        }
      }

      return formattedIntegerPart + formattedDecimalPart;
    }
  };

  // Construct the formatted currency string
  const formattedAmount = formatAmount(amount);

  let currencyString = '';
  if (bDefault) {
    currencyString += strCode;
    if (bAddCodeValueSeparator) {
      currencyString += strCodeValueSeparator;
    }
  }
  currencyString += formattedAmount;
  if (bDefault && bCodeAppendedEnd) {
    if (bAddCodeValueSeparator) {
      currencyString += strCodeValueSeparator;
    }
    currencyString += strCode;
  }

  return currencyString;
};

export { formatCurrency, currencyOpts };
