import React from 'react';
import { useTranslation } from 'react-i18next';
import translationKeys from 'translations/translationKeys';
import { useNavigate } from 'react-router-dom';
import '../../styles/_page-not-found.scss';

const PageNotFound = () => {
  const { msg163, msg164, msg165 } = translationKeys;
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="not-found-container">
      <h2 className="title">Oops!</h2>
      <h4 className="sub-title">{`404 - ${t(msg163)}`}</h4>
      <p className="not-found-txt">{t(msg164)}</p>
      <button type="button" className="btn btn-primary redirect" onClick={() => navigate('/')}>{t(msg165)}</button>
    </div>

  );
};

export default PageNotFound;
