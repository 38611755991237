import React from 'react';
import { Outlet } from 'react-router-dom';
import LeftSideBar from './LeftSideBar';
import RightSideBar from './RightSideBar';
import '../../../styles/_dashboard.scss';

const Body = () => (
  <div className="body">
    <LeftSideBar />
    <Outlet />
    <RightSideBar />
  </div>
);

export default Body;
