import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import PropTypes from 'prop-types';

const ShowAlert = () => {
  const { alerts } = useSelector((store) => store.betSlip);
  const { t } = useTranslation();
  const [errorMessages, setErrorMessages] = useState(null);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const priorityIndex = alerts.findIndex((alert) => (alert.type === 'minStake' || alert.type === 'maxStake'));
    setVisible(true);
    if (priorityIndex > -1) {
      setErrorMessages(new Array(
        <p>
          {t(alerts[priorityIndex].displayText)}
          {' '}
          {alerts[priorityIndex].alertMessage}
        </p>,
      ));
    } else {
      const messages = alerts?.length > 0 ? alerts.map((alert, index) => {
        const key = `${alert.type}-${index}`;
        return (
          <p key={key}>
            {t(alert.displayText)}
            {' '}
            {alert.alertMessage}
          </p>
        );
      }) : null;
      setErrorMessages(messages);
    }
  }, [t, alerts]);

  return visible && (
    <div className="line">
      <div className="div-msg">
        <button type="button" onClick={() => setVisible(false)} className="close-button">x</button>
        <span>Info!</span>
        {errorMessages}
      </div>
    </div>
  );
};

// ShowAlert.propTypes = {
//   text: PropTypes.string.isRequired,
// };

export default ShowAlert;
