import React from 'react';
import PropTypes from 'prop-types';

const Error = ({ text }) => (
  <div
    className="loading"
    style={{
      width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
    }}
  >
    {text}
  </div>
);

Error.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Error;
