import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  authParams, getBase64Hash, token,
} from 'components/helpers/base64AuthParams';
import translationKeys from 'translations/translationKeys';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});

const { msg29 } = translationKeys;

export const getProviders = createAsyncThunk(
  'Recharge/getProviders',
  async (agentId, thunkAPI) => {
    const api = `/agent-servicev2/ser_get_airtime_providers?userID=${agentId}`;
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    const signatureParam = `/agent-servicev2/ser_get_airtime_providersuserID=${agentId}`;
    try {
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
        'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}`)}`,
        Authorization: token ? `Bearer ${token}` : '',
      };
      const resp = await client.get(url, { headers });
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(msg29);
    }
  },
);

const initialState = {
  voucherProviders: [],
  loadingProviders: false,
  errorLoadingProviders: '',
};

const getVoucherProvidersSlice = createSlice({
  name: 'get voucher providers',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getProviders.pending, (state) => ({
        ...state,
        loadingProviders: true,
      }))
      .addCase(getProviders.fulfilled, (state, { payload }) => {
        const str = payload && payload['2'] && payload['2'][5];
        const values = str && str.split('-');
        // Map over the array of values and convert them to numbers with a radix of 10 (decimal)
        const mappedValues = values && values.map((value) => parseInt(value, 10));
        const keys = Object.keys(payload);
        const agentCode = keys[0];
        return {
          ...state,
          voucherProviders: mappedValues && mappedValues,
          loadingProviders: false,
          agentCode,
        };
      })

      .addCase(getProviders.rejected, (state) => ({
        ...state,
        loadingProviders: false,
        errorLoadingProviders: msg29,
      }));
  },
});

export default getVoucherProvidersSlice.reducer;
