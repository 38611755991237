/* eslint prefer-destructuring: 0 */
/* eslint no-param-reassign: 0 */
/* eslint  max-len: 0 */
/* eslint  no-restricted-syntax: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import bonusOptions from 'components/helpers/bonusOptions';
import getLanguage from 'components/helpers/getLanguage';
import translationKeys from 'translations/translationKeys';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});

const {
  msg29, msg126, msg122, msg123, msg124, msg125, msg129, msg156, msg160, msg161, msg166, msg167,
} = translationKeys;
export const getBetConfigs = createAsyncThunk(
  'configs/getBetConfigs',
  async (thunkAPI) => {
    const lang = getLanguage();
    const api = `/configs/?lang=${lang}`;
    const signatureParam = `/configs/lang=${lang}`;
    const url = `/${apiVersion2}${api}`;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}`)}`,
        },
      };
      const resp = await client.get(url, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(msg29);
    }
  },
);

// Event restriction Extra reducer
export const getEventRestrictions = createAsyncThunk(
  'configs/getEventRestrictions',
  async (thunkAPI) => {
    // endpoint to load event restrictions
    const api = '/restriction/events/cached';
    const signatureParam = '/restriction/events/cached';
    const url = `/${apiVersion2}${api}`;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}`)}`,
        },
      };
      const resp = await client.get(url, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(msg29);
    }
  },
);

const initialState = {
  betConfigs: [],
  bonuses: {},
  bonus: 0,
  bonusPercent: 0,
  taxes: [],
  exciseTax: 0,
  exciseTaxConfig: {},
  tvaConfig: {},
  stakeWithoutTax: 0,
  finalWinnings: 0,
  bets: [],
  betsContent: [],
  finalBets: [],
  odds: 0,
  maxOdds: 10000,
  gains: 0,
  potentialWinnings: 0,
  potentialWinningsWithBonus: 0,
  integral: false,
  system: false,
  simple: false,
  multiple: false,
  multipleSimple: false,
  integralAllowed: false,
  totalPotGainsIntegral: 0,
  numIntegralTickets: 0,
  integralTickets: [],
  simpleTickets: [],
  betType: '',
  stake: 300,
  amountPrecision: -0.01,
  oddsPrecision: 2,
  maxPotWin: 6000000,
  validMultiple: false,
  multipleIntegral: false,
  minEventsMultiple: 2,
  maxEventsMultiple: 25,
  toggleToMultiple: false,
  toggleToMultipleSimple: false,
  minEvents: 2,
  maxEvents: 25,
  bettingOptions: 'SIMPLE,MULTIPLE,INTEGRAL,SYSTEM,MULTI_SIMPLE',
  minStake: 300,
  maxStake: 2000000,
  stepValue: 100,
  pick: '',
  alerts: [],
  eventRestrictions: [],
  eligibleRestrictions: [],
  isLoading: true,
  error: '',
};

const checkIntegralBets = (items) => {
  const matchIdCounts = items.reduce((counts, item) => {
    counts[item.matchId] = (counts[item.matchId] || 0) + 1;
    return counts;
  }, []);

  const hasMultipleSameMatchIds = Object.values(matchIdCounts).some((count) => count >= 2);

  return hasMultipleSameMatchIds;
};

function makeIntegralsCombinations(bets) {
  if (Array.isArray(bets)) {
    const matchEvents = {};
    const integralCombinations = [];

    if (bets.length > 1) {
      bets.forEach((bet, index) => {
        if (typeof matchEvents[bet.matchId] === 'undefined') {
          matchEvents[bet.matchId] = [];
        }
        matchEvents[bet.matchId].push(index);
      });
      const matchIds = Object.keys(matchEvents);

      const generateCombinations = (currentIndex, currentCombination) => {
        if (currentIndex === matchIds.length) {
          integralCombinations.push(currentCombination.slice()); // Push a copy of current combination
          return;
        }

        const currentEvent = matchIds[currentIndex];
        const outcomes = matchEvents[currentEvent];

        for (const element of outcomes) {
          currentCombination.push(element);
          generateCombinations(currentIndex + 1, currentCombination);
          currentCombination.pop();
        }
      };

      generateCombinations(0, []);
    }

    return integralCombinations;
  }
  return [];
}

const generateIntegralPick = (integralsCombinations, bets) => {
  let pick = '';
  if (Array.isArray(integralsCombinations)) {
    const integralPicks = integralsCombinations.map((integralBet) => integralBet.map((index) => bets[index].pick).join('B'));
    pick += integralPicks.join('T');
  }
  return pick;
};

const roundingNumber = (number, precision) => {
  if (typeof number === 'number' && typeof precision === 'number') {
    const roundUp = Math.sign(precision) >= 0;
    precision = Math.abs(precision);

    if (precision < 1 && precision > 0) {
      let roundedNumber = number;
      let numberOfDecimalPlaces = 0;
      if (precision.toString().includes('.')) {
        numberOfDecimalPlaces = precision.toString().split('.')[1].length;
      }

      if (number.toString().includes('.')) {
        if (roundUp) {
          roundedNumber = number + (0.5 * 10 ** (-numberOfDecimalPlaces));
        } else {
          roundedNumber = number - (0.5 * 10 ** (-numberOfDecimalPlaces));
        }
      }

      return Number(roundedNumber.toFixed(numberOfDecimalPlaces));
    }
    let multiple = number / precision;
    multiple = roundUp ? Math.ceil(multiple) : Math.floor(multiple);
    const finalNumber = precision * multiple;
    return finalNumber;
  }
  throw new Error('Invalid values');
};

const addAlert = (alerts, alert) => {
  if (Array.isArray(alerts)) {
    const existingAlertIndex = alerts.findIndex((a) => a.type === alert.type);

    if (existingAlertIndex > -1) {
      alerts[existingAlertIndex] = alert;
    } else {
      alerts.push(alert);
    }

    return alerts;
  }
  return [];
};

const removeAlert = (alerts, alertType) => {
  if (Array.isArray(alerts)) {
    return alerts.filter((alert) => alert.type !== alertType);
  }
  return [];
};

// function to get list of eligible restrictions for bets

const getEligibleEventRestrictions = (bets, eventRestrictions) => {
  if (Array.isArray(bets) && Array.isArray(eventRestrictions)) {
    return eventRestrictions.filter((restriction) => bets.some((bet) => {
      // if all criteria are null, that event Restriction is not eligible
      if ((restriction.sportId === '' || restriction.sportId === null)
      && (restriction.categoryId === '' || restriction.categoryId === null)
      && (restriction.marketId === null || restriction.marketId === '')
      && (restriction.tournamentId === '' || restriction.tournamentId === null)) {
        return false;
      }

      // check if a bet matches the required criteria where at least one of the criteria is not null
      const matchSport = restriction.sportId === '' || restriction.sportId === null || restriction.sportId === bet.match.info.sport.id;
      const matchCategory = restriction.categoryId === '' || restriction.categoryId === null || restriction.categoryId === bet.match.info.category.id;
      const matchMarket = restriction.marketId === '' || restriction.marketId === null || restriction.marketId === bet.id;
      const matchTournament = restriction.tournamentId === '' || restriction.tournamentId === null || restriction.tournamentId === bet.match.info.tournament.id;

      return matchSport && matchCategory && matchMarket && matchTournament;
    })).map((validRestriction) => eventRestrictions.findIndex((eventRestriction) => eventRestriction.id === validRestriction.id));
  }
  return [];
};

// function to get the accumlated event restriction
const getAccumulatedEventRestriction = (eligibleEventRestrictionsIndexes, eventRestrictions) => {
  if (Array.isArray(eligibleEventRestrictionsIndexes) && Array.isArray(eventRestrictions)) {
    if (eligibleEventRestrictionsIndexes.length === 0) {
      return null;
    }

    if (eligibleEventRestrictionsIndexes.length > 0) {
      const minEvents = eligibleEventRestrictionsIndexes.reduce((accumulator, index) => Math.max(accumulator, eventRestrictions[index].intMinEvent), -1);
      const maxEvents = eligibleEventRestrictionsIndexes.reduce((accumulator, index) => Math.min(accumulator, eventRestrictions[index].intMaxEvent), Infinity);

      return { minEvents, maxEvents };
    }
  }

  return null;
};

const calculateBonus = (bets, config) => {
  let bonus = 0;
  let bonusPercent = 0;
  let bonusEventCount = 0;

  if (Object.keys(config.bonuses).length > 0 && Array.isArray(bets)) {
    const minOddForBonus = config.bonuses?.min_odd ?? 0;
    const minEvtForBonus = config.bonuses?.min_evt ?? 0;
    const maxEvtForBonus = config.bonuses?.max_evt ?? 0;

    // Count the number of events with eligible odds for bonus
    bonusEventCount = bets.filter((bet) => bet.odds >= minOddForBonus).length;

    // Verify eligibility of bet slip for bonus
    const eligibleForBonus = bonusEventCount >= minEvtForBonus && bonusEventCount <= maxEvtForBonus && bonusEventCount === bets.length;

    // if eligible for bonus get the bonus detail with the greatest bonus percent
    if (eligibleForBonus) {
      const eligibleBonusPercent = config.bonuses.detail.filter((detail) => (bonusEventCount >= detail.evt_num)).map((detail) => (detail.percent));
      if (eligibleBonusPercent.length > 0) {
        bonusPercent = Math.max(...eligibleBonusPercent);
      }

      if (bonusPercent > 0) {
        const bonusPercentage = bonusPercent / 100;
        // if deduct_stake
        //   bonus = (Potential Winnings - stakeWithoutTax) * bonusPercentage
        // else
        //   bonus = Potential Winnings * bonusPercentage;
        bonus = config?.bonuses?.deduct_stake ? ((config.potentialWinnings - config.stakeWithoutTax) * bonusPercentage) : config.potentialWinnings * bonusPercentage;
        bonus = roundingNumber(bonus, config.amountPrecision);

        // if max_bn && bonus > max_bn
        //      bonus = max_bn
        if (config?.bonuses?.max_bn && (bonus > config.bonuses.max_bn)) {
          bonus = config.bonuses.max_bn;
        }
      }
    }
  }

  return { bonus, bonusPercent, bonusEventCount };
};

const applyTax = (finalGains, potWinnings, configs) => {
  let stakeWithoutTax = 0;
  let TVA = 0;
  let finalWinnings = 0;
  let exciseTax = 0;

  if (typeof finalGains === 'number' && typeof potWinnings === 'number' && (Object.keys(configs).length >= 4)) {
    const tvaPercentage = configs?.tvaConfig ? configs.tvaConfig.value / 100 : 0;
    const maxWinnings = configs?.tvaConfig ? configs.tvaConfig.max_winning : 0;

    // if b_Deduct_From_Principal (Stake)
    //    Tax calculated and deducted from stake
    // else
    //   Operator pays the tax
    if (configs?.exciseTaxConfig?.b_Deduct_From_Principal) {
      const taxPercentage = configs?.exciseTaxConfig.value / 100;
      // if stake_include_tax
      //  stakeWithTax = stake/(1 + tax percentage/100)
      // else
      //  stakeWithTax = stake
      stakeWithoutTax = configs?.exciseTaxConfig.stake_includes_tax ? configs.stake / (1 + taxPercentage) : configs.stake;
      stakeWithoutTax = roundingNumber(stakeWithoutTax, configs.amountPrecision);
      // taxAmount = stake * taxPercentage
      exciseTax = roundingNumber(stakeWithoutTax * taxPercentage, configs.amountPrecision);
    } else {
      stakeWithoutTax = configs.stake;
    }

    // if b_Deduct_From_Principal (Winnings)
    //    Tax calculated and deducted from stake
    // else
    //   Operator pays the tax

    if (configs?.tvaConfig?.b_Deduct_From_Principal) {
      // if is_applied_max_winning and Potential winnings + bonus >= maximum winnings or !is_applied_max_winning
      //     Tax is calculated from (Potential winnings + bonus) and deducted from it
      // else
      //    Tax is not calculated and nothing is deducted from (Potential winnings + bonus)
      if ((configs?.tvaConfig?.is_applied_max_winning === true && finalGains > maxWinnings) || configs?.tvaConfig?.is_applied_max_winning === false) {
        // if !bl_include_stake (Winnings)
        //   if bl_include_bonus (Winnings)
        //      Tax = (PotentialWinnings + AccumulatedBonus - stakeWithoutTax) * taxPercentage
        //   else
        //      Tax = (PotentialWinnings - stakeWithoutTax) * taxPercentage
        // else
        //   if bl_include_bonus (Winnings)
        //      Tax = (PotentialWinnings + AccumulatedBonus) * taxPercentage
        //   else
        //      Tax = PotentialWinnings * taxPercentage
        if (configs?.tvaConfig?.bl_include_stake === false) {
          TVA = configs?.tvaConfig.bl_include_bonus ? (finalGains - stakeWithoutTax) * tvaPercentage : (potWinnings - stakeWithoutTax) * tvaPercentage;
          TVA = roundingNumber(TVA, configs.amountPrecision);
        } else {
          TVA = configs?.tvaConfig.bl_include_bonus ? finalGains * tvaPercentage : (potWinnings) * tvaPercentage;
          TVA = roundingNumber(TVA, configs.amountPrecision);
        }

        finalWinnings = finalGains - TVA;
      } else {
        finalWinnings = finalGains;
      }
    } else {
      finalWinnings = finalGains;
    }
  }

  return {
    stakeWithoutTax, exciseTax, finalWinnings, TVA,
  };
};

const updateBetType = (state, previousBetLength) => {
  let betType = '';
  let integralTickets = [];
  let integralCombinations = [];
  let simple = false;
  let integral = false;
  let multiple = false;
  let validMultiple = false;
  let multipleIntegral = false;
  let toggleToMultipleSimple = state.toggleToMultipleSimple;
  let toggleToMultiple = state.toggleToMultiple;
  let multipleSimple = state.multipleSimple;
  let simpleTickets = state.simpleTickets;
  let pick = ''; // A parameter used by the backend to process a bet
  let updatedAlerts = [...state.alerts];
  let integralsNotAllowedError = false;
  let minEventsMultiple = state.minEventsMultiple;
  let maxEventsMultiple = state.maxEventsMultiple;
  let numIntegralTickets = 0;
  const bets = [...state.bets];
  const bettingOptions = state?.bettingOptions?.split(',');
  let maxEventsCountError = false;
  let minEventsCountMultipleError = false;
  let maxEventsCountMultipleError = false;
  let eligibleRestrictions = [];

  // If betslip change in size, check eligible events restrictions
  if (typeof previousBetLength === 'number' && bets.length !== previousBetLength) {
    eligibleRestrictions = getEligibleEventRestrictions(bets, state.eventRestrictions);
    const accumulatedEventRestriction = getAccumulatedEventRestriction(eligibleRestrictions, state.eventRestrictions);
    if (accumulatedEventRestriction) {
      minEventsMultiple = accumulatedEventRestriction.minEvents;
      maxEventsMultiple = accumulatedEventRestriction.maxEvents;
    } else {
      minEventsMultiple = initialState.minEventsMultiple;
      maxEventsMultiple = state.maxEvents;
    }
  }

  if (bets.length > state.maxEvents) {
    bets.pop();
    maxEventsCountError = true;
    updatedAlerts = addAlert(updatedAlerts, {
      type: 'maxEventsCount',
      displayText: msg123,
      alertMessage: state.maxEvents.toString(),
    });
  }

  let hasIntegrals = checkIntegralBets(bets); // Bet Slip has outcomes with same matchId from more than one event

  if (!maxEventsCountError) {
    updatedAlerts = removeAlert(updatedAlerts, 'maxEventsCount');
  }

  if (state.integralAllowed) {
    if (hasIntegrals) {
      integralCombinations = makeIntegralsCombinations(bets);
      toggleToMultipleSimple = false;
      toggleToMultiple = false;
      // check if integral is an invalid integral of multiple
      const invalidMinEventsIntegralMultiple = integralCombinations.some((integralCombination) => integralCombination.length < minEventsMultiple && integralCombination.length > 1);
      const invalidMaxEventsIntegralMultiple = integralCombinations.some((integralCombination) => integralCombination.length > maxEventsMultiple && integralCombination.length > 1);

      // if invalid integral of multiple, set required messages
      // pop up last event added if it exceed the maxEventsMultiple
      // recheck for integral
      if (invalidMinEventsIntegralMultiple) {
        minEventsCountMultipleError = true;
        updatedAlerts = addAlert(updatedAlerts, {
          type: 'minEventsCountMultiple',
          displayText: msg166,
          alertMessage: minEventsMultiple.toString(),
        });
      } else if (invalidMaxEventsIntegralMultiple) {
        maxEventsCountMultipleError = true;
        updatedAlerts = addAlert(updatedAlerts, {
          type: 'maxEventsCountMultiple',
          displayText: msg167,
          alertMessage: maxEventsMultiple.toString(),
        });
        bets.pop();
        validMultiple = true;
        hasIntegrals = checkIntegralBets(bets);
      } else {
        validMultiple = true;
      }

      if (hasIntegrals) {
        integral = true;
        betType = bettingOptions?.[2];
        integralCombinations = makeIntegralsCombinations(bets);
        if (integralCombinations.some((integralCombination) => integralCombination.length > 1)) {
          multipleIntegral = true;
        }
        numIntegralTickets = integralCombinations.length;

        integralTickets = integralCombinations.map((integralCombination) => ({
          bets: integralCombination,
          potentialWinnings: 0,
          potentialWinningsWithBonus: 0,
          odds: 0,
          bonus: 0,
          bonusPercent: 0,
          exciseTax: 0,
          exciseTaxConfig: {},
          tvaConfig: {},
          TVA: 0,
          finalWinnings: 0,
        }));
      }
    }
  }

  if (!state.integralAllowed && hasIntegrals) {
    integral = false;
    integralsNotAllowedError = true;
    updatedAlerts = addAlert(updatedAlerts, {
      type: 'integralsNotAllowed',
      displayText: msg160,
      alertMessage: '',
    });
    bets.pop();
  }

  if (!integralsNotAllowedError) {
    updatedAlerts = removeAlert(updatedAlerts, 'integralsNotAllowed');
  }

  const uniqueMatchIds = new Set(bets.map((item) => item.matchId));
  const multipleBets = bets.length === uniqueMatchIds.size; // Check if no two items in the bet slips have same matchId

  if (bets.length === 1) {
    simple = true;
    betType = bettingOptions?.[0];
    multipleSimple = false;
    toggleToMultipleSimple = false;
    toggleToMultiple = false;
    simpleTickets = [];
  }

  // If number of events in bet slip is greater than 1 and all events have different matchId
  if (bets.length > 1 && multipleBets === true) {
    // if it was a multiple simple then keep it so
    if (multipleSimple) {
      betType = state.betType;
      // If the bet was a multiple simple and meets requirements for minEvents, change bet into a multiple
      if ((bets.length >= state.minEvents) && toggleToMultipleSimple) {
        toggleToMultiple = true;
        toggleToMultipleSimple = false;
        validMultiple = true;
      }
    } else if (bets.length >= minEventsMultiple && bets.length <= maxEventsMultiple && bets.length >= state.minEvents) {
      // else if number of events is greater than or equal to minEvents for multiple
      // and less than or equal to maxEvents for multiple then is a multiple bet
      multiple = true;
      betType = bettingOptions?.[1];
      validMultiple = true;
      toggleToMultipleSimple = false;
      toggleToMultiple = false;
    } else if (bets.length < state.minEvents) {
      // number of events is less than the minimum number of events change multiple into simple multiple
      toggleToMultipleSimple = true;
      toggleToMultiple = false;
    } else if (bets.length < minEventsMultiple) {
      // else if number of events is less then minEvents for multiple then invalid multiple
      minEventsCountMultipleError = true;
      updatedAlerts = addAlert(updatedAlerts, {
        type: 'minEventsCountMultiple',
        displayText: msg166,
        alertMessage: minEventsMultiple.toString(),
      });
      multiple = true;
      betType = bettingOptions?.[1];
      toggleToMultipleSimple = false;
      toggleToMultiple = false;
    } else {
      // else number of events is greater then maxEvents for multiple then invalid multiple
      bets.pop();
      toggleToMultipleSimple = false;
      toggleToMultiple = false;
      maxEventsCountMultipleError = true;
      updatedAlerts = addAlert(updatedAlerts, {
        type: 'maxEventsCountMultiple',
        displayText: msg167,
        alertMessage: maxEventsMultiple.toString(),
      });
      validMultiple = true;
      multiple = true;
      betType = bettingOptions?.[1];
    }
  }

  if (!minEventsCountMultipleError) {
    updatedAlerts = removeAlert(updatedAlerts, 'minEventsCountMultiple');
  }

  if (!maxEventsCountMultipleError) {
    updatedAlerts = removeAlert(updatedAlerts, 'maxEventsCountMultiple');
  }

  if (simple || multiple) {
    pick = bets.map((obj) => obj.pick).join('B');
  } else if (integral) {
    pick = generateIntegralPick(integralCombinations, bets);
  } else if (multipleSimple) {
    pick = simpleTickets.map((simpleTicket) => bets[simpleTicket.betIndex].pick).join('-');
  }

  return {
    ...state,
    bets,
    betType,
    integral,
    simple,
    multiple,
    multipleSimple,
    validMultiple,
    multipleIntegral,
    simpleTickets,
    pick,
    alerts: updatedAlerts,
    integralTickets,
    numIntegralTickets,
    minEventsMultiple,
    maxEventsMultiple,
    eligibleRestrictions,
    toggleToMultiple,
    toggleToMultipleSimple,
  };
};

const betConfigsSlice = createSlice({
  name: 'betConfigs',
  initialState,
  reducers: {
    addToBetSlip: (state, { payload }) => {
      const bet = payload;
      let simpleTickets = state.simpleTickets;
      const updatedBet = {
        ...bet,
        inBetSlip: true,
      };
      if (state.multipleSimple) {
        const newSimpleTicket = {
          betIndex: state.bets.length,
          stake: state.minStake,
          stakeWithoutTax: state.minStake,
          potentialWinnings: 0,
          potentialWinningsWithBonus: 0,
          odds: 0,
          bonus: 0,
          bonusPercent: 0,
          exciseTax: 0,
          exciseTaxConfig: {},
          tvaConfig: {},
          TVA: 0,
          finalWinnings: 0,
        };

        simpleTickets = [...simpleTickets, newSimpleTicket];
      }

      const updatedState = updateBetType({ ...state, bets: [...state.bets, updatedBet], simpleTickets }, state.bets.length);
      return { ...state, ...updatedState };
    },
    addCombToBetSlip: (state, { payload }) => {
      const bet = payload;
      let simpleTickets = state.simpleTickets;
      const updatedBet = {
        ...bet,
        inBetSlip: true,
      };
      if (state.multipleSimple) {
        const newSimpleTicket = {
          betIndex: state.bets.length,
          stake: state.minStake,
          stakeWithoutTax: state.minStake,
          potentialWinnings: 0,
          potentialWinningsWithBonus: 0,
          odds: 0,
          bonus: 0,
          bonusPercent: 0,
          exciseTax: 0,
          exciseTaxConfig: {},
          tvaConfig: {},
          TVA: 0,
          finalWinnings: 0,
        };

        simpleTickets = [...simpleTickets, newSimpleTicket];
      }
      const updatedState = updateBetType({ ...state, bets: [...state.bets, updatedBet], simpleTickets }, state.bets.length);
      return { ...state, ...updatedState };
    },
    addCouponsToBetSlip: (state, { payload }) => {
      let simpleTickets = state.simpleTickets;
      if (state.multipleSimple) {
        const newSimpleTicket = {
          betIndex: state.bets.length,
          stake: state.minStake,
          stakeWithoutTax: state.minStake,
          potentialWinnings: 0,
          potentialWinningsWithBonus: 0,
          odds: 0,
          bonus: 0,
          bonusPercent: 0,
          exciseTax: 0,
          exciseTaxConfig: {},
          tvaConfig: {},
          TVA: 0,
          finalWinnings: 0,
        };

        simpleTickets = [...simpleTickets, newSimpleTicket];
      }
      const updatedState = updateBetType({ ...state, bets: [...state.bets, ...payload], simpleTickets }, state.bets.length);
      return { ...state, ...updatedState };
    },
    toggleMultipleBet: (state, { payload }) => {
      const betType = payload;
      let multiple = state.multiple;
      let multipleSimple = state.multipleSimple;
      let simpleTickets = state.simpleTickets;
      let stakeWithoutTax = state.minStake;
      const bettingOptions = state?.bettingOptions?.split(',');
      const multipleSimpleBetTypeIndex = bettingOptions.lastIndexOf('MULTI_SIMPLE');
      const multipleBetTypeIndex = bettingOptions.lastIndexOf('MULTIPLE');

      const exciseTaxConfig = state.taxes.find((item) => item.str_taxable_entity === 'STAKE');

      if (exciseTaxConfig?.b_Deduct_From_Principal) {
        const taxPercentage = exciseTaxConfig.value / 100;
        // if stake_include_tax
        //  stakeWithTax = stake/(1 + tax percentage/100)
        // else
        //  stakeWithTax = stake
        stakeWithoutTax = exciseTaxConfig.stake_includes_tax ? state.minStake / (1 + taxPercentage) : state.minStake;
        stakeWithoutTax = roundingNumber(stakeWithoutTax, state.amountPrecision);
      }

      if (betType === bettingOptions[multipleSimpleBetTypeIndex]) {
        multipleSimple = true;
        multiple = false;
        simpleTickets = state.bets.map((bet, index) => ({
          betIndex: index,
          stake: state.minStake,
          stakeWithoutTax,
          potentialWinnings: 0,
          potentialWinningsWithBonus: 0,
          odds: 0,
          bonus: 0,
          bonusPercent: 0,
          exciseTax: 0,
          exciseTaxConfig: {},
          tvaConfig: {},
          TVA: 0,
          finalWinnings: 0,
        }));
      } else if (betType === bettingOptions[multipleBetTypeIndex]) {
        multiple = true;
        multipleSimple = false;
        simpleTickets = [];
      }

      const updatedState = updateBetType({
        ...state,
        multiple,
        multipleSimple,
        simpleTickets,
        betType,
      }, state.bets.length);

      return {
        ...state,
        ...updatedState,
      };
    },
    updateStake: (state, { payload }) => {
      const stake = payload?.stake ? Number(payload.stake) : Number(payload);
      let updatedAlerts = [...state.alerts];
      let stakeWithoutTax = 0;
      let minStakeError = false;
      let maxStakeError = false;
      let stepValueError = false;

      if ((stake % state.stepValue) !== 0) {
        stepValueError = true;
        updatedAlerts = addAlert(updatedAlerts, {
          type: 'stepValue',
          displayText: msg161,
          alertMessage: state.stepValue.toString(),
        });
      }

      if (!stepValueError) {
        updatedAlerts = removeAlert(updatedAlerts, 'stepValue');
      }

      if (stake < state.minStake && state.bets.length > 0) {
        minStakeError = true;
        updatedAlerts = addAlert(updatedAlerts, {
          type: 'minStake',
          displayText: msg156,
          alertMessage: state.minStake.toString(),
        });
      } else if (stake > state.maxStake && state.bets.length > 0) {
        maxStakeError = true;
        updatedAlerts = addAlert(updatedAlerts, {
          type: 'maxStake',
          displayText: msg125,
          alertMessage: state.maxStake.toString(),
        });
      }

      if (!minStakeError) {
        updatedAlerts = removeAlert(updatedAlerts, 'minStake');
      }

      if (!maxStakeError) {
        updatedAlerts = removeAlert(updatedAlerts, 'maxStake');
      }

      const exciseTaxConfig = state.taxes.find((item) => item.str_taxable_entity === 'STAKE');
      if (exciseTaxConfig?.b_Deduct_From_Principal) {
        const taxPercentage = exciseTaxConfig.value / 100;
        // if stake_include_tax
        //  stakeWithTax = stake/(1 + tax percentage/100)
        // else
        //  stakeWithTax = stake
        stakeWithoutTax = exciseTaxConfig.stake_includes_tax ? stake / (1 + taxPercentage) : stake;
        stakeWithoutTax = roundingNumber(stakeWithoutTax, state.amountPrecision);
      } else {
        stakeWithoutTax = stake;
      }
      if (typeof payload?.index === 'number') {
        const index = payload.index;
        const updatedSimpleTickets = state.simpleTickets.map((simpleTicket, currentIndex) => ((currentIndex === index) ? { ...simpleTicket, stake, stakeWithoutTax } : simpleTicket));

        return {
          ...state,
          alerts: updatedAlerts,
          simpleTickets: updatedSimpleTickets,
        };
      }
      return {
        ...state,
        stake,
        alerts: updatedAlerts,
        stakeWithoutTax,
      };
    },

    stepStake: (state, { payload }) => {
      let stakeWithoutTax = 0;
      let updatedAlerts = [...state.alerts];
      let stake = typeof payload?.index === 'number' ? state.simpleTickets[payload.index].stake : state.stake;
      stake = payload.increment ? stake + state.stepValue : stake - state.stepValue;

      if (stake % state.stepValue !== 0) {
        stake = Math.floor(stake / state.stepValue) * state.stepValue;
      }

      if (stake < state.minStake) {
        stake = state.minStake;
      } else if (stake > state.maxStake) {
        stake = state.maxStake;
      }

      updatedAlerts = removeAlert(updatedAlerts, 'stepValue');
      updatedAlerts = removeAlert(updatedAlerts, 'minStake');
      updatedAlerts = removeAlert(updatedAlerts, 'maxStake');

      const exciseTaxConfig = state.taxes.find((item) => item.str_taxable_entity === 'STAKE');

      if (exciseTaxConfig?.b_Deduct_From_Principal) {
        const taxPercentage = exciseTaxConfig.value / 100;
        // if stake_include_tax
        //  stakeWithTax = stake/(1 + tax percentage/100)
        // else
        //  stakeWithTax = stake
        stakeWithoutTax = exciseTaxConfig.stake_includes_tax ? stake / (1 + taxPercentage) : stake;
        stakeWithoutTax = roundingNumber(stakeWithoutTax, state.amountPrecision);
      } else {
        stakeWithoutTax = stake;
      }

      if (typeof payload?.index === 'number') {
        const index = payload.index;
        const updatedSimpleTickets = state.simpleTickets.map((simpleTicket, currentIndex) => ((currentIndex === index) ? { ...simpleTicket, stake, stakeWithoutTax } : simpleTicket));

        return {
          ...state,
          alerts: updatedAlerts,
          simpleTickets: updatedSimpleTickets,
        };
      }

      return {
        ...state,
        stake,
        stakeWithoutTax,
        alerts: updatedAlerts,
      };
    },
    calculateOdds: (state) => {
      let maxOddError = false;
      let updatedAlerts = [...state.alerts];
      const bets = state.bets;
      const precision = -(10 ** (-state.oddsPrecision));

      if ((state.simple || state.multiple) && !state.integral && !state.multipleSimple) {
        let odds = 0;
        const listOfOdds = bets.map((item) => item.odds);
        const totalOdds = bets.length > 0 ? listOfOdds.reduce((acc, odd) => acc * odd, 1) : 0;
        odds = roundingNumber(totalOdds, precision);

        if (totalOdds > state.maxOdds) {
          maxOddError = true;
          updatedAlerts = addAlert(updatedAlerts, {
            type: 'maxOdd',
            displayText: msg122,
            alertMessage: state.maxOdds.toString(),
          });
          odds = roundingNumber(state.maxOdds, precision);
        }

        if (!maxOddError) {
          updatedAlerts = removeAlert(updatedAlerts, 'maxOdd');
        }

        return {
          ...state,
          odds,
          alerts: updatedAlerts,
        };
      }

      if (state.integral) {
        // integral odds calculation
        const integralTickets = [...state.integralTickets];

        // Calculate Total odds for each ticket
        const newIntegralTickets = integralTickets.map((integralTicket) => {
          const betIndexes = integralTicket.bets;
          const totalOdds = betIndexes.length > 0 ? betIndexes.map((betIndex) => bets[betIndex].odds).reduce((acc, odd) => acc * odd, 1) : 0;
          let odds = roundingNumber(totalOdds, precision);
          if (totalOdds > state.maxOdds) {
            maxOddError = maxOddError || true;
            odds = roundingNumber(state.maxOdds, precision);
          }

          return {
            ...integralTicket,
            odds,
          };
        });

        if (!maxOddError) {
          updatedAlerts = removeAlert(updatedAlerts, 'maxOdd');
        } else {
          updatedAlerts = addAlert(updatedAlerts, {
            type: 'maxOdd',
            displayText: msg122,
            alertMessage: state.maxOdds.toString(),
          });
        }
        return { ...state, integralTickets: newIntegralTickets, alerts: updatedAlerts };
      }
      // multiple simple odds calculation
      const simpleTickets = [...state.simpleTickets];

      // Set Total odds for each simple ticket
      const newSimpleTickets = simpleTickets.map((simpleTicket) => {
        const odds = bets[simpleTicket.betIndex].odds;

        return {
          ...simpleTicket,
          odds,
        };
      });

      return { ...state, simpleTickets: newSimpleTickets, alerts: updatedAlerts };
    },

    calculateGains: (state) => {
      let potentialWinnings = 0;
      let updatedAlerts = [...state.alerts];
      let maxPotentialWinningsError = false;
      const odds = state.odds;

      // Calculate Gain for simple and multiple bets
      if ((state.simple || state.multiple) && !state.integral && !state.multipleSimple) {
        potentialWinnings = roundingNumber(odds * state.stakeWithoutTax, state.amountPrecision);

        if (potentialWinnings > state.maxPotWin) {
          maxPotentialWinningsError = true;
          updatedAlerts = addAlert(updatedAlerts, {
            type: 'maxPotWin',
            displayText: msg126,
            alertMessage: state.maxPotWin.toString(),
          });
          potentialWinnings = state.maxPotWin;
        }

        if (!maxPotentialWinningsError) {
          updatedAlerts = removeAlert(updatedAlerts, 'maxPotWin');
        }

        return {
          ...state,
          potentialWinnings,
          alerts: updatedAlerts,
        };
      }

      if (state.integral) {
        // Integral bets gain calculations
        const integralTickets = [...state.integralTickets];

        // Calculate Gain for each ticket
        const newIntegralTickets = integralTickets.map((integralTicket) => {
          const integralTicketTotalOdds = integralTicket.odds;
          let integralTicketPotWinnings = roundingNumber(integralTicketTotalOdds * state.stakeWithoutTax, state.amountPrecision);

          if (integralTicketPotWinnings > state.maxPotWin) {
            maxPotentialWinningsError = maxPotentialWinningsError || true;

            integralTicketPotWinnings = state.maxPotWin;
          }

          return {
            ...integralTicket,
            potentialWinnings: integralTicketPotWinnings,
          };
        });

        if (!maxPotentialWinningsError) {
          updatedAlerts = removeAlert(updatedAlerts, 'maxPotWin');
        } else {
          updatedAlerts = addAlert(updatedAlerts, {
            type: 'maxPotWin',
            displayText: msg126,
            alertMessage: state.maxPotWin.toString(),
          });
        }
        return { ...state, integralTickets: newIntegralTickets, alerts: updatedAlerts };
      }
      // multiple simple bets gain calculations
      const simpleTickets = [...state.simpleTickets];

      // Calculate Gain for each ticket
      const newSimpleTickets = simpleTickets.map((simpleTicket) => {
        let simpleTicketPotWinnings = roundingNumber(simpleTicket.odds * simpleTicket.stakeWithoutTax, state.amountPrecision);

        if (simpleTicketPotWinnings > state.maxPotWin) {
          maxPotentialWinningsError = maxPotentialWinningsError || true;

          simpleTicketPotWinnings = state.maxPotWin;
        }

        return {
          ...simpleTicket,
          potentialWinnings: simpleTicketPotWinnings,
        };
      });

      if (!maxPotentialWinningsError) {
        updatedAlerts = removeAlert(updatedAlerts, 'maxPotWin');
      } else {
        updatedAlerts = addAlert(updatedAlerts, {
          type: 'maxPotWin',
          displayText: msg126,
          alertMessage: state.maxPotWin.toString(),
        });
      }

      return { ...state, simpleTickets: newSimpleTickets, alerts: updatedAlerts };
    },

    applyBonus: (state) => {
      let maxPotentialWinningsError = false;
      let maxEventsForBonusCountError = false;
      let updatedAlerts = [...state.alerts];

      // Calculate Bonus for simple and multiple bets
      if ((state.simple || state.multiple) && !state.integral && !state.multipleSimple) {
        let potentialWinningsWithBonus = state.potentialWinnings;
        const potentialWinnings = state.potentialWinnings;
        const { bonus, bonusPercent, bonusEventCount } = calculateBonus(state.bets, {
          bonuses: state.bonuses,
          potentialWinnings: state.potentialWinnings,
          stakeWithoutTax: state.stakeWithoutTax,
          amountPrecision: state.amountPrecision,
        });

        if (bonus > 0) {
          potentialWinningsWithBonus = potentialWinnings + bonus;

          if (potentialWinningsWithBonus > state.maxPotWin) {
            potentialWinningsWithBonus = state.maxPotWin;
            maxPotentialWinningsError = true;
            updatedAlerts = addAlert(updatedAlerts, {
              type: 'maxPotWin',
              displayText: msg126,
              alertMessage: state.maxPotWin.toString(),
            });
          }

          if (bonusEventCount > state.bonuses?.max_evt) {
            maxEventsForBonusCountError = true;
            updatedAlerts = addAlert(updatedAlerts, {
              type: 'maxEventsForBonusCount',
              displayText: msg124,
              alertMessage: state.bonuses?.max_evt?.toString() ?? '',
            });
          }

          if (!maxEventsForBonusCountError) {
            updatedAlerts = removeAlert(updatedAlerts, 'maxEventsForBonusCount');
          }

          if (!maxPotentialWinningsError) {
            updatedAlerts = removeAlert(updatedAlerts, 'maxPotWin');
          }
        }

        return {
          ...state,
          bonus,
          potentialWinningsWithBonus,
          bonusPercent,
          alerts: updatedAlerts,
        };
      }

      if (state.integral) {
        // Calculate Bonus for integral bets
        const integralTickets = [...state.integralTickets];

        // Calculate Bonus for each ticket
        const newIntegralTickets = integralTickets.map((integralTicket) => {
          const integralTicketBetIndexes = integralTicket.bets;
          const integralTicketBets = state.bets.filter((bet, index) => integralTicketBetIndexes.includes(index));
          let integralTicketPotWinningsWithBonus = integralTicket.potentialWinnings;
          const { bonus, bonusPercent, bonusEventCount } = calculateBonus(integralTicketBets, {
            bonuses: state.bonuses,
            potentialWinnings: integralTicket.potentialWinnings,
            stakeWithoutTax: state.stakeWithoutTax,
            amountPrecision: state.amountPrecision,
          });

          if (bonus > 0) {
            integralTicketPotWinningsWithBonus = integralTicket.potentialWinnings + bonus;

            if (integralTicketPotWinningsWithBonus > state.maxPotWin) {
              integralTicketPotWinningsWithBonus = state.maxPotWin;
              maxPotentialWinningsError = true;
            }

            if (bonusEventCount > state.bonuses?.max_evt) {
              maxEventsForBonusCountError = true;
            }
          }

          return {
            ...integralTicket,
            bonus,
            potentialWinningsWithBonus: integralTicketPotWinningsWithBonus,
            bonusPercent,
          };
        });

        if (!maxPotentialWinningsError) {
          updatedAlerts = removeAlert(updatedAlerts, 'maxPotWin');
        } else {
          updatedAlerts = addAlert(updatedAlerts, {
            type: 'maxPotWin',
            displayText: msg126,
            alertMessage: state.maxPotWin.toString(),
          });
        }

        if (!maxEventsForBonusCountError) {
          updatedAlerts = removeAlert(updatedAlerts, 'maxEventsForBonusCount');
        } else {
          updatedAlerts = addAlert(updatedAlerts, {
            type: 'maxEventsForBonusCount',
            displayText: msg124,
            alertMessage: state.bonuses?.max_evt?.toString() ?? '',
          });
        }
        return { ...state, integralTickets: newIntegralTickets, alerts: updatedAlerts };
      }

      // Calculate Bonus for multiple simple bets
      const simpleTickets = [...state.simpleTickets];

      // Calculate Bonus for each ticket
      const newSimpleTickets = simpleTickets.map((simpleTicket) => {
        const simpleTicketBets = state.bets.filter((bet, index) => simpleTicket.betIndex === index);
        let simpleTicketPotWinningsWithBonus = simpleTicket.potentialWinnings;
        const { bonus, bonusPercent, bonusEventCount } = calculateBonus(simpleTicketBets, {
          bonuses: state.bonuses,
          potentialWinnings: simpleTicket.potentialWinnings,
          stakeWithoutTax: simpleTicket.stakeWithoutTax,
          amountPrecision: state.amountPrecision,
        });

        if (bonus > 0) {
          simpleTicketPotWinningsWithBonus = simpleTicket.potentialWinnings + bonus;

          if (simpleTicketPotWinningsWithBonus > state.maxPotWin) {
            simpleTicketPotWinningsWithBonus = state.maxPotWin;
            maxPotentialWinningsError = true;
          }

          if (bonusEventCount > state.bonuses?.max_evt) {
            maxEventsForBonusCountError = true;
          }
        }

        return {
          ...simpleTicket,
          bonus,
          potentialWinningsWithBonus: simpleTicketPotWinningsWithBonus,
          bonusPercent,
        };
      });

      if (!maxPotentialWinningsError) {
        updatedAlerts = removeAlert(updatedAlerts, 'maxPotWin');
      } else {
        updatedAlerts = addAlert(updatedAlerts, {
          type: 'maxPotWin',
          displayText: msg126,
          alertMessage: state.maxPotWin.toString(),
        });
      }

      if (!maxEventsForBonusCountError) {
        updatedAlerts = removeAlert(updatedAlerts, 'maxEventsForBonusCount');
      } else {
        updatedAlerts = addAlert(updatedAlerts, {
          type: 'maxEventsForBonusCount',
          displayText: msg124,
          alertMessage: state.bonuses?.max_evt?.toString() ?? '',
        });
      }
      return { ...state, simpleTickets: newSimpleTickets, alerts: updatedAlerts };
    },

    deleteFromBetSlip: (state, { payload }) => {
      const updatedBets = state.bets.filter((bet) => bet.uniqueId !== payload);
      let simpleTickets = state.simpleTickets;
      if (state.multipleSimple) {
        simpleTickets = state.simpleTickets.filter((simpleTicket) => state.bets[simpleTicket.betIndex].uniqueId !== payload).map((simpleTicket, index) => ({ ...simpleTicket, betIndex: index }));
      }
      const updatedState = updateBetType({ ...state, bets: updatedBets, simpleTickets }, state.bets.length);
      return { ...state, ...updatedState };
    },

    clearBetSlip: (state) => ({
      ...initialState,
      eventRestrictions: state.eventRestrictions,
      betConfigs: state.betConfigs,
      bonuses: state.bonuses,
      minStake: state.minStake,
      maxStake: state.maxStake,
      minEvents: state.minEvents,
      maxEvents: state.maxEvents,
      maxOdds: state.maxOdds,
      maxPotWin: state.maxPotWin,
      amountPrecision: state.amountPrecision,
      oddsPrecision: state.oddsPrecision,
      bettingOptions: state.bettingOptions,
      stepValue: state.stepValue,
      taxes: state.taxes,
    }),

    calculateTax: (state) => {
      // Calculate Tax for simple and multiple bets
      if (state.stake >= state.minStake && state.stake <= state.maxStake && (state.simple || state.multiple) && !state.integral && !state.multipleSimple) {
        if (state.bets.length > 0) {
          const taxes = state.taxes;
          const exciseTaxConfig = taxes.find((item) => item.str_taxable_entity === 'STAKE');
          const tvaConfig = taxes.find((item) => item.str_taxable_entity === 'WINNINGS');

          const {
            stakeWithoutTax, exciseTax, finalWinnings, TVA,
          } = applyTax(state.potentialWinningsWithBonus, state.potentialWinnings, {
            tvaConfig,
            exciseTaxConfig,
            stake: state.stake,
            amountPrecision: state.amountPrecision,
          });
          const exciseTaxInfo = exciseTaxConfig ? { value: exciseTaxConfig.value, type: exciseTaxConfig.type } : {};
          const tvaInfo = tvaConfig ? { value: tvaConfig.value, type: tvaConfig.type } : {};

          return {
            ...state,
            stakeWithoutTax,
            exciseTax,
            exciseTaxConfig: exciseTaxInfo,
            tvaConfig: tvaInfo,
            finalWinnings,
            TVA,
          };
        }

        return {
          ...state,
          finalWinnings: 0,
          exciseTax: 0,
          TVA: 0,
        };
      }

      if (state.integral) {
        // Calculate Tax for integral bets
        if (state.stake >= state.minStake && state.stake <= state.maxStake) {
          const integralTickets = [...state.integralTickets];
          let updatedStakeWithoutTax = state.stakeWithoutTax;

          // Calculate Tax for each ticket
          const newIntegralTickets = integralTickets.map((integralTicket) => {
            const taxes = state.taxes;
            const exciseTaxConfig = taxes.find((item) => item.str_taxable_entity === 'STAKE');
            const tvaConfig = taxes.find((item) => item.str_taxable_entity === 'WINNINGS');

            const {
              stakeWithoutTax, exciseTax, finalWinnings, TVA,
            } = applyTax(integralTicket.potentialWinningsWithBonus, integralTicket.potentialWinnings, {
              tvaConfig,
              exciseTaxConfig,
              stake: state.stake,
              amountPrecision: state.amountPrecision,
            });
            updatedStakeWithoutTax = stakeWithoutTax;
            const exciseTaxInfo = exciseTaxConfig ? { value: exciseTaxConfig.value, type: exciseTaxConfig.type } : {};
            const tvaInfo = tvaConfig ? { value: tvaConfig.value, type: tvaConfig.type } : {};

            return {
              ...integralTicket,
              exciseTax,
              exciseTaxConfig: exciseTaxInfo,
              tvaConfig: tvaInfo,
              finalWinnings,
              TVA,
            };
          });

          return {
            ...state,
            integralTickets: newIntegralTickets,
            stakeWithoutTax: updatedStakeWithoutTax,
          };
        }

        return {
          ...state,
          finalWinnings: 0,
          exciseTax: 0,
          TVA: 0,
        };
      }

      // Calculate Tax for multiple simple bets
      const simpleTickets = [...state.simpleTickets];

      // Calculate Tax for each ticket
      const newSimpleTickets = simpleTickets.map((simpleTicket) => {
        const taxes = state.taxes;
        const exciseTaxConfig = taxes.find((item) => item.str_taxable_entity === 'STAKE');
        const tvaConfig = taxes.find((item) => item.str_taxable_entity === 'WINNINGS');

        const {
          stakeWithoutTax, exciseTax, finalWinnings, TVA,
        } = applyTax(simpleTicket.potentialWinningsWithBonus, simpleTicket.potentialWinnings, {
          tvaConfig,
          exciseTaxConfig,
          stake: simpleTicket.stake,
          amountPrecision: state.amountPrecision,
        });

        const exciseTaxInfo = exciseTaxConfig ? { value: exciseTaxConfig.value, type: exciseTaxConfig.type } : {};
        const tvaInfo = tvaConfig ? { value: tvaConfig.value, type: tvaConfig.type } : {};

        return {
          ...simpleTicket,
          exciseTax,
          exciseTaxConfig: exciseTaxInfo,
          tvaConfig: tvaInfo,
          stakeWithoutTax,
          finalWinnings,
          TVA,
        };
      });

      return {
        ...state,
        simpleTickets: newSimpleTickets,
      };
    },

  },

  extraReducers: (builder) => {
    builder
      .addCase(getBetConfigs.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(getBetConfigs.fulfilled, (state, { payload }) => {
        const minStake = payload?.configs?.find((item) => item.key === 'INT_MINIMUM_STAKE');
        const maxStake = payload?.configs?.find((item) => item.key === 'INT_MAXIMUM_STAKE');
        const maxEvents = payload?.configs?.find((item) => item.key === 'INT_MAXIMUM_EVENT');
        const minEvents = payload?.configs?.find((item) => item.key === 'INT_MINIMUM_EVENT');
        const maxOdds = payload?.configs?.find((item) => item.key === 'INT_MAXUMUM_ODDS');
        const maxPotWin = payload?.configs?.find((item) => item.key === 'INT_MAXIMUM_POTENTIAL_WINNINGS');
        const bettingOptions = payload?.configs?.find((item) => item.key === 'ALLOWED_BET_TYPES');
        const amountPrecision = payload?.configs?.find((item) => item.key === 'INT_ROUND_VALUE_AT');
        const stepValue = payload?.configs?.find((item) => item.key === 'INT_SOCCER_STAKE_STEP_AMOUNT');
        const oddPrecision = payload?.configs?.find((item) => item.key === 'INT_ODDS_PRECISON');
        const integralAllowed = payload?.configs?.find((item) => item.key === 'B_ALLOW_INTERGRAL_BETS');
        let taxes;
        if (payload?.tax) {
          taxes = payload.tax;
        } else {
          taxes = {};
        }

        return {
          ...state,
          betConfigs: payload?.configs ?? [],
          bonuses: payload?.bonus ?? bonusOptions,
          stake: Number(minStake?.value ?? 300),
          minStake: Number(minStake?.value ?? 300),
          maxStake: Number(maxStake?.value ?? 2000000),
          maxEvents: Number(maxEvents?.value ?? 25),
          minEvents: Number(minEvents?.value ?? 2),
          maxOdds: Number(maxOdds?.value ?? 10000),
          maxPotWin: Number(maxPotWin?.value ?? 6000000),
          amountPrecision: Number(amountPrecision?.value ?? -0.01),
          oddsPrecision: Number(oddPrecision?.value ?? 2),
          bettingOptions: bettingOptions?.value ?? 'SIMPLE,MULTIPLE,INTEGRAL,SYSTEM,MULTI_SIMPLE',
          integralAllowed: Boolean(Number(integralAllowed?.value ?? 0)),
          stepValue: Number(stepValue?.value ?? 100),
          taxes,
          isLoading: false,
        };
      })

      .addCase(getBetConfigs.rejected, (state) => ({
        ...state,
        minStake: 300,
        stake: 300,
        maxStake: 2000000,
        maxEvents: 25,
        minEvents: 2,
        maxOdds: 10000,
        maxPotWin: 6000000,
        amountPrecision: -1,
        oddsPrecision: 2,
        bettingOptions: 'SIMPLE,MULTIPLE,INTEGRAL,SYSTEM,MULTI_SIMPLE',
        stepValue: 100,
        taxes: [],
        bonuses: {},
        isLoading: false,
        error: msg129,
      }))
      // Adding the event restrictions extra reducer
      .addCase(getEventRestrictions.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(getEventRestrictions.fulfilled, (state, { payload }) => {
        const response = Array.isArray(payload) ? payload : [];

        const fieldsToRemove = ['created_by', 'created_at', 'updated_by', 'updated_at'];

        const eventRestrictions = response.map((restriction) => {
          fieldsToRemove.forEach((field) => {
            delete restriction[field];
          });
          return restriction;
        });
        return {
          ...state,
          eventRestrictions,
          isLoading: false,
        };
      })
      .addCase(getEventRestrictions.rejected, (state) => ({
        ...state,
        isLoading: false,
        error: msg129,
      }));
  },
});
export const {
  addToBetSlip,
  deleteFromBetSlip,
  calculateOdds,
  calculateTax,
  calculateGains,
  selectBetType,
  clearBetSlip,
  getMaxCombinedOdds,
  applyBonus,
  getTax,
  updateStake,
  addCombToBetSlip,
  stepStake,
  clearPriceField,
  clearStake,
  addCouponsToBetSlip,
  toggleMultipleBet,
} = betConfigsSlice.actions;

export default betConfigsSlice.reducer;
