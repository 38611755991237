import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import getLanguage from 'components/helpers/getLanguage';
import translationKeys from 'translations/translationKeys';
import tokenText from 'components/helpers/tokenText';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const { msg29 } = translationKeys;
export const placeBet = createAsyncThunk(
  'bets/placeBet',
  async (objects, thunkAPI) => {
    const lang = getLanguage();
    const token = JSON.parse(localStorage.getItem('token'));
    const requests = objects.map((obj) => {
      const requestParams = `lang=${lang}&uid=${obj.agentId}&pwd=${obj.agentPwd}&phn=${obj.agentPhone}&pick=${obj.pick}&amt=${obj.amt}&chl=${obj.chl}&tkt=${obj.tkt}&ref=${obj.time}&dvr=${obj.dvr}&dtp=${obj.dtp}&kid=${obj.kid}&vers=${obj.ver}&imei=${obj.imei}`;
      const api = `/bet?${requestParams}`;
      const url = `${apiBaseUrl}/${apiVersion2}${api}`;
      const signatureParam = `/bet${requestParams}`;
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
        'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}${JSON.stringify({})}`)}`,
        Authorization: token ? `Bearer ${token}` : '',
      };
      return client.post(url, {}, { headers });
    });
    try {
      const responses = await Promise.all(requests);
      return responses.map((response) => response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.response.data.message || 'An error occurred',
        erc: error.response.data.erc,
      });
    }
  },
);

const initialState = {
  placeBets: [],
  isLoading: false,
  success: false,
  failed: false,
  msg: '',
  error: '',
};

const placeBetsSlice = createSlice({
  name: 'place-bet',
  initialState,
  reducers: {
    resetSuccess: (state) => ({
      ...state,
      success: false,
      msg: '',
    }),
  },

  extraReducers: (builder) => {
    builder
      .addCase(placeBet.pending, (state) => ({
        ...state,
        isPlaceBetLoading: true,
        success: false,
      }))
      .addCase(placeBet.fulfilled, (state, { payload }) => {
        let success;
        let failed;
        let placeBets;
        let msg;
        if (payload.every((response) => response[0]['14'])) {
          success = true;
          failed = false;
          placeBets = payload.map((response) => response[0].data);
        } else if (payload.some((response) => response[0]?.msg?.length > 0)) {
          failed = false;
          success = false;
          const index = payload.findIndex((response) => response[0]?.msg?.length > 0);
          msg = payload[index][0].msg;
        }

        return {
          ...state,
          placeBets,
          isPlaceBetLoading: false,
          success,
          failed,
          msg,
          error: '',
        };
      })

      .addCase(placeBet.rejected, (state, { payload }) => {
        let msg;
        if (payload && payload.erc && payload.erc === '001') {
          msg = tokenText;
        } else { msg = msg29; }
        return {
          ...state,
          isPlaceBetLoading: false,
          success: false,
          msg,
          error: msg29,
        };
      });
  },
});

export const { resetSuccess } = placeBetsSlice.actions;

export default placeBetsSlice.reducer;
