import fetchTranslations from './fetchTranslations'; // Not currently in use

const getTranslations = () => {
  fetchTranslations().then((translations) => console.log('Translations', translations));
};

getTranslations(); // Not currently in use
const translationKeys = {
  msg1: 'Connexion',
  msg2: 'Code agent',
  msg3: 'Code PIN',
  msg4: 'Entrer votre code',
  msg5: 'Entrer votre PIN',
  msg6: 'Le code agent ne doit pas être vide',
  msg7: 'Code PIN ne doit pas être vide',
  msg8: 'Afficher le code PIN ?',
  msg9: 'Tendances chaudes',
  msg10: 'Aujourdhui',
  msg11: 'Meilleures Compétitions',
  msg12: 'Tableau de bord',
  msg13: 'Sports',
  msg14: 'Jackpot',
  msg15: 'Pays',
  msg16: 'Vérifier ticket',
  msg17: 'Historique',
  msg18: 'Recherche',
  msg19: 'Retrait',
  msg20: 'Solde',
  msg21: 'Dépôt',
  msg22: 'Chargement....',
  msg23: 'Erreur de connexion, veuillez contacter le support !',
  msg24: 'Pin Expiré ! Vous devez le changer',
  msg25: 'Agent Inconnu',
  msg26: 'Vous n êtes pas authorisé à vous connecter via ce périphérique',
  msg27: 'Pin Agent Incorrect',
  msg28: 'Agent Suspendu',
  msg29: 'Une erreur est survenue sur le serveur',
  msg30: 'Pin Changé avec Succes',
  msg31: 'Votre Pin ne doit pas contenir de chiffres consécutifs',
  msg32: 'Deux chiffres ont été répétés dans votre pin',
  msg33: 'Le pari n\'est pas enregistre sur le serveur',
  msg34: 'Pari deja annulé',
  msg35: 'Pari Annulé avec succes',
  msg36: 'L\'annulation du pari n\'a pas eté effectifié!Veuillez reprendre l\'operaion',
  msg37: 'Ticket payé avec succès',
  msg38: 'Délai d\'annulation expiré',
  msg39: 'Vous n\'avez pas le priviliegé d\'effectuer cette action.',
  msg40: 'Ce montant est disponible',
  msg41: 'Veuillez entrer une combinaison valide.',
  msg42: 'l\'élément existe déjà.',
  msg43: 'Êtes-vous sûr de vouloir payer ce ticket ?',
  msg44: 'Êtes-vous sûr de vouloir annuler ce billet ?',
  msg45: 'Confirmer la recharge de',
  msg46: 'succès!',
  msg47: 'Retrait effectué avec succès, veuillez imprimer le ticket.',
  msg48: 'Patientez SVP...',
  msg49: 'valider',
  msg50: 'Payer',
  msg51: 'Annuler',
  msg52: 'Oui',
  msg53: 'Imprimer',
  msg54: 'Montant',
  msg55: 'Soumettre',
  msg56: 'Code client',
  msg57: 'Tickets vendus',
  msg58: 'Tickets annulés',
  msg59: 'Tickets payés',
  msg60: 'Recharge directe',
  msg61: 'Vente coupon',
  msg62: 'Date de début',
  msg63: 'Date',
  msg64: 'Type',
  msg65: 'Ref Code',
  msg66: 'TransID',
  msg67: 'Aucune entrée disponible dans la plage sélectionnée.',
  msg68: 'date de fin',
  msg69: 'Historique des détails',
  msg70: 'VÉRIFIER TICKET',
  msg71: 'Numéro de ticket',
  msg72: 'Code du client',
  msg73: 'Code OTP',
  msg74: 'Effacer tout',
  msg75: 'Mise',
  msg76: 'Gain potentiel',
  msg77: 'Mise Totale',
  msg78: 'Accepter les changements de cotes',
  msg79: 'Placer mon pari',
  msg80: 'Déconnexion',
  msg81: 'Modifier le PIN',
  msg82: 'Combinaison',
  msg83: 'Code coupon',
  msg84: 'Entrer le code du client',
  msg85: 'Entrer le code OTP',
  msg86: 'Ticket vendu',
  msg87: 'Ticket annulé',
  msg88: 'Ticket payé',
  msg89: 'Le code client est incorrect',
  msg90: 'Le montant est supérieur au solde de l agent',
  msg91: 'La Transaction a echoué',
  msg92: 'Aucune entrée disponible',
  msg93: 'Resultat',
  msg94: 'Total de buts',
  msg95: 'But d\'équipe',
  msg96: 'Buteur',
  msg97: 'Différence de but',
  msg98: 'Score',
  msg99: 'Le nouveau code PIN ne doit pas être vide',
  msg100: 'Le nouveau Pin doit être différent de l\'ancien',
  msg101: 'Réinitialiser le code PIN',
  msg102: 'Ancien code PIN',
  msg103: 'Nouveau code PIN',
  msg104: 'Confirmer le nouveau code PIN',
  msg105: 'Pas de Ticket existant avec cette reference.',
  msg106: 'Ticket Perdant.',
  msg107: 'Gains dés payés.',
  msg108: 'Ticket expir&eacute; !!',
  msg109: 'Client inconnu !!',
  msg110: 'Erreur lors de la validation: Ce code n\'est pas disponible !',
  msg111: 'Code retrait déja expiré !',
  msg112: 'Code retrait déja déboursé !',
  msg113: 'la generation du code pin nest pas definie',
  msg114: 'Parametre incomplet',
  msg115: 'Le montant des ventes de la carte n est pas défini sur le serveur',
  msg116: 'Autorisation échouée',
  msg117: 'Code inconnu',
  msg118: 'Agent inconnu',
  msg119: 'Le stock est vide',
  msg120: 'Le montant est supérieur au solde de l\'agent',
  msg121: 'Recharge directe',
  msg122: 'Les cotes ne peuvent pas dépasser',
  msg123: 'Le nombre d\'événements ne peut pas dépasser',
  msg124: 'Le nombre maximal d\'événements pour le bonus ne peut pas dépasser',
  msg125: 'La mise ne peut pas dépasser',
  msg126: 'Les gains ne peuvent pas dépasser',
  msg127: 'Code retrait suspendu !',
  msg128: 'Ce compte est bloqué !',
  msg129: 'Ce compte est désactivé !',
  msg130: 'Operation non autorisee!',
  msg131: 'Périphérique Non Assigner',
  msg132: 'Votre session a expiré. Veuillez vous reconnecter.',
  msg133: 'Bons',
  msg134: 'Entrer le montant',
  msg135: 'VÉRIFIER TICKET',
  msg136: 'Veuillez confirmer le nouveau code PIN',
  msg137: 'Les codes PIN ne correspondent pas',
  msg138: 'Votre vente de credit a eté suspendu .Contactez l administrateur',
  msg139: 'no outcomes for event',
  msg140: 'Coupon introuvable!',
  msg141: 'ml_message_agent_not_allow_channel',
  msg142: 'Le coupon est expiré!',
  msg143: 'Veuillez entrer un coupon valide.',
  msg144: 'Montant du pari invalide.',
  msg145: 'Mise après impôt',
  msg146: 'Impôt',
  msg147: 'Paramètre manquant!',
  msg148: 'Utilisateur inconnu',
  msg149: 'Gains finale',
  msg150: 'Vous devrez attendre 1 mins apres le dernier depot pour effectuer un autre',
  msg151: 'Rechercher',
  msg152: 'Entrer votre recherche',
  msg153: 'MES SELECTIONS',
  msg154: 'Actuellement, aucun jeu n\'est disponible. Veuillez réessayer ultérieurement.',
  msg155: 'Dépôt échoué.',
  msg156: 'La mise ne peut pas être inférieure à',
  msg157: 'TOUTES COMB. CÔTE',
  msg158: 'Gain Pot.',
  msg159: 'Une erreur est survenue avec l\'imprimante',
  msg160: 'Paris intégraux non autorisés',
  msg161: 'La mise doit être un multiple de',
  msg162: 'Gains Totaux',
  msg163: 'Page introuvable',
  msg164: 'La page que vous recherchez n’existe pas ou a été déplacée.',
  msg165: 'Allez à la page d’accueil',
  msg166: 'Le nombre d\'événements pour ce pari doit être d\'au moins',
  msg167: 'Le nombre d\'événements pour ce pari doit être au maximum de',
};

export default translationKeys;
