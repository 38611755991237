import axios from 'axios';
import formatTranlations from './formatTranlations';

const fetchTranslations = async () => {
  const url = 'https://sheet.best/api/sheets/81b884b4-02f3-4ec1-b48d-18e9d070c763';
  try {
    const response = await axios.get(url);
    const formattedTranslations = await formatTranlations(response.data);
    return formattedTranslations;
  } catch (error) {
    console.error('Error fetching translations:', error);
    return null;
  }
};

export default fetchTranslations;
