const getSortedMatches = (todayMatches) => {
  const matches = [];

  if (todayMatches?.categories) {
    const { categories } = todayMatches;
    categories.forEach((category) => {
      category.tournaments.forEach((tournament) => matches.push(...tournament.matches));
    });

    matches.sort((m1, m2) => {
      const difference = m2.time - m1.time;

      if (difference < 0) {
        return 1;
      } if (difference > 0) {
        return -1;
      }
      return 0;
    });
  }

  return matches;
};

export default getSortedMatches;
