/* eslint  max-len: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import { userObj } from 'components/common/requestBody';
import translationKeys from 'translations/translationKeys';
import getLanguage from 'components/helpers/getLanguage';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const { msg29 } = translationKeys;
export const getEventMarkets = createAsyncThunk(
  'markets/event-markets',
  async (marchId, thunkAPI) => {
    const lang = getLanguage();
    const api = `/sport/event/0/${marchId}/market?channel_id=${userObj.chl}&lang=${lang}`;
    const signatureParam = `/sport/event/0/${marchId}/marketchannel_id=${userObj.chl}&lang=${lang}`;
    const url = `/${apiVersion2}${api}`;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}`)}`,
        },
      };
      const resp = await client.get(url, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(msg29);
    }
  },
);

const initialState = {
  eventMarkets: [],
  eventId: '',
  isLoading: false,
  successGetEventMarkets: false,
  error: '',
};

const eventMarketSlice = createSlice({
  name: 'Event markets',
  initialState,
  reducers: {
    selectFromDetail: (state, { payload }) => {
      const updatedMarkets = [{
        ...state.eventMarkets[0],
        markets: state.eventMarkets[0].markets.map((market) => ({
          ...market,
          outcomes: market.outcomes.map((o) => (o.uniqueId === payload ? { ...o, inBetSlip: true } : o)),
        })),
      }];
      return {
        ...state,
        eventMarkets: updatedMarkets,
      };
    },
    unSelectFromDetail: (state, { payload }) => {
      const updatedMarkets = [{
        ...state.eventMarkets[0],
        markets: state.eventMarkets[0].markets.map((market) => ({
          ...market,
          outcomes: market.outcomes.map((o) => (o.uniqueId === payload ? { ...o, inBetSlip: false } : o)),
        })),
      }];
      return {
        ...state,
        eventMarkets: updatedMarkets,
      };
    },
    setAllDetailsToFalse: (state) => {
      const updatedMarkets = [{
        ...state.eventMarkets[0],
        markets: state.eventMarkets[0].markets.map((market) => ({
          ...market,
          outcomes: market.outcomes.map((outcome) => ({
            ...outcome,
            inBetSlip: false,
          })),
        })),
      }];
      return {
        ...state,
        eventMarkets: updatedMarkets,
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getEventMarkets.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(getEventMarkets.fulfilled, (state, action) => {
        // const filteredMarkets = action.payload.filter((item) => item.name === 'All');
        const eventId = action.meta.arg;
        const updatedMarkets = [{
          ...action.payload[0],
          markets: action.payload[0].markets.map((market) => ({
            ...market,
            outcomes: market.outcomes.map((outcome) => ({
              ...outcome,
              inBetSlip: false,
              matchId: eventId,
              uniqueId: eventId + outcome.name + outcome.market.name,
            })),
          })),
        }];
        return {
          ...state,
          isLoading: false,
          eventMarkets: updatedMarkets,
          successGetEventMarkets: true,
          eventId,
        };
      })

      .addCase(getEventMarkets.rejected, (state) => ({
        ...state,
        isLoading: false,
        error: msg29,
      }));
  },
});

export const {
  selectFromDetail,
  unSelectFromDetail,
  setAllDetailsToFalse,
} = eventMarketSlice.actions;

export default eventMarketSlice.reducer;
