/* eslint max-len: 0 */
/* eslint jsx-a11y/click-events-have-key-events: 0 */
/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React, { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { getMeilleursComp, getTournamentId } from 'redux/meilleursCompetition/meilleursCompSlice';
import { getTournamentMatches } from 'redux/tournament-matches/tournamentMatchesSlice';
import { getCategoryTournaments } from 'redux/category-tournaments/categoryTournamentsSlice';
import { getMeilleursParis } from 'redux/meilleursParis/meilleursParisSlice';
import { getTodayMatches } from 'redux/today-matches/todayMatchesSlice';
import { getAllSports, selectSport } from 'redux/all-sports/allSportsSLice';
import { useTranslation } from 'react-i18next';
import toggleMobileMenu from 'components/helpers/toggleMobileMenu';
import defaultSportId from 'components/helpers/defaultSport';
import translationKeys from 'translations/translationKeys';
import formatData from 'components/helpers/formateData';
import { IoMdClose } from 'react-icons/io';
import '../../../styles/_dashboard.scss';
import { getCategories } from 'redux/categories/categoriesSlice';
import fire from '../../../assets/images/mathys/svg/fire.svg';
import circle from '../../../assets/images/mathys/svg/play-circle.svg';
import camera from '../../../assets/images/mathys/svg/camera-reels.svg';
import arrowRight from '../../../assets/images/mathys/svg/arrow-right.svg';
import sportImage from '../../../assets/images/mathys/png/sport.png';
import homeImage from '../../../assets/images/mathys/png/home.png';
import defaultImage from '../../../assets/images/mathys/png/football.png';
// import jackpotImage from '../../../assets/images/mathys/png/jackpot.png';
import baseUrls from '../../../config';

const MobileSideMenu = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const imgUrl = baseUrls.BASE_URL_IMG;
  const dispatch = useDispatch();
  const primaryClass = document.getElementById('group-btn');
  const sportsMenu = document.getElementById('sub-menu-mobile');
  const tournamentsMenu = document.getElementById('tournaments');
  const [openItemId, setOpenItemId] = useState(null);
  const { allSports } = useSelector((store) => store.allSports);
  const { meilleursComp } = useSelector((store) => store.meilleursComp);
  const { categories } = useSelector((store) => store.categories);
  const { tournaments } = useSelector((store) => store.tournaments);
  const { topMatchesMkts } = useSelector((store) => store.topMatchesMkts);
  const { allTopMatches } = useSelector((store) => store.allTopMatches);
  const { successTodayMatches } = useSelector((store) => store.todayMatches);
  const { successTopMatches } = useSelector((store) => store.meilleursParis);
  const sportSelected = JSON.parse(localStorage.getItem('sportSelected'));
  const text = JSON.parse(localStorage.getItem('text'));
  const buttonId = JSON.parse(localStorage.getItem('buttonId'));
  let filtered;
  if (meilleursComp && meilleursComp.length > 0) {
    filtered = meilleursComp.filter((item) => item.sport_id === sportSelected);
  }

  const toggleAcc = (itemId) => {
    setOpenItemId((prevItemId) => (prevItemId === itemId ? null : itemId));
  };

  const showSportsMenu = () => {
    primaryClass.classList.add('hide-group-btn');
    sportsMenu.classList.remove('hide-sub-menu-mobile');
  };
  const hideSportsMenu = () => {
    sportsMenu.classList.add('hide-sub-menu-mobile');
  };

  const showTournamentsBar = () => {
    tournamentsMenu.classList.remove('tournament');
  };
  const formattedData = formatData(topMatchesMkts);
  const data = {
    markets: formattedData,
    allMarketsRequired: false,
    sport: (allTopMatches && allTopMatches.length > 0) && allTopMatches[0].id,
  };
  const list = [

    {
      id: uuidv4(), img: fire, path: 'meilleurs-paris', text: 'Tendances chaudes', className: 'normal', func: () => toggleMobileMenu(), func2: () => !successTopMatches && dispatch(getMeilleursParis(data)),
    },
    {
      id: uuidv4(), img: circle, path: 'today-matches', text: 'Aujourdhui', className: 'normal', func: () => toggleMobileMenu(), func2: () => !successTodayMatches && dispatch(getTodayMatches(defaultSportId)), func3: () => dispatch(selectSport(defaultSportId)),
    },
    // {
    //   id: uuidv4(), img: camera, text: 'Direct', path: 'direct', className: 'normal', func: showSideBar,
    // },
    {
      id: uuidv4(), img: camera, text: 'Meilleures Compétitions', path: pathname, className: 'normal', func: () => hideSportsMenu(), func2: () => dispatch(getMeilleursComp()), func3: () => showTournamentsBar(),
    },

  ];

  const links = [
    {
      id: uuidv4(), path: 'home', text: 'Tableau de bord', img: homeImage, className: 'btn btn-secondary', func: () => toggleMobileMenu(),
    },
    {
      id: uuidv4(),
      path: 'meilleurs-paris',
      text: 'Sports',
      img: sportImage,
      className: 'btn btn-secondary show-sports-memu',
      func2: () => dispatch(getMeilleursParis(data)),
      func3: () => dispatch(getAllSports()),
      func: () => showSportsMenu(),
    },
    // {
    //   id: uuidv4(), path: 'jackpot', text: 'Jackpot', img: jackpotImage, className: 'btn btn-secondary', func: hideSideBar,
    // },
  ];
  const { msg15, msg11, msg9 } = translationKeys;
  return (
    <aside className="hide-mobile-sidebar d-md-none" id="hide-mobile-sidebar">
      <div id="close-mobile-menu">
        <button
          type="button"
          onClick={() => {
            toggleMobileMenu();
          }}
        >
          <IoMdClose style={{ color: '#333', fontSize: '1.5rem' }} />
        </button>
      </div>
      <div className="wrapper">
        <div className="group-btn hide-group-btn" id="group-btn">
          {links.map((link) => (
            <NavLink
              to={link.path}
              key={link.id}
              className={({ isActive }) => (isActive ? `${link.className}` : link.className)}
              onClick={() => {
                if (typeof link.func === 'function') {
                  localStorage.setItem('buttonId', JSON.stringify(msg9)); // Set active button to Top matches
                  link.func();
                }
                if (typeof link.func2 === 'function') {
                  link.func2();
                  localStorage.setItem('topSportSelected', data.sport); // To be used at MeilleursParis.jsx when page is refreshed to refetch data
                }
                if (typeof link.func3 === 'function') {
                  link.func3();
                }
              }}
            >
              <img src={link.img} alt="" />
              {t(link.text)}
            </NavLink>
          ))}
        </div>
        <div className="sub-menu-mobile hide-sub-menu-mobile" id="sub-menu-mobile">
          <ul className="list-unstyled">
            {list.map((item) => (
              <li key={item.id}>
                <button
                  type="button"
                  className={buttonId === item.text ? 'btn normal selected' : 'btn normal'}
                  onClick={() => {
                    if (typeof item.func === 'function') {
                      item.func();
                    }
                    if (typeof item.func2 === 'function') {
                      item.func2();
                    }
                    if (typeof item.func3 === 'function') {
                      item.func3();
                    }
                    navigate(item.path);
                    localStorage.setItem('text', JSON.stringify(item.text)); // Used to determine which menu items to display on third menu
                    localStorage.setItem('buttonId', JSON.stringify(item.text)); // Set active button
                  }}
                >
                  <img src={item.img} alt="" />
                  <span>{t(item.text)}</span>
                </button>
              </li>
            ))}

            {allSports?.length > 0 ? allSports.map((item) => item && (
              <li key={item.id}>
                <button
                  type="button"
                  className={buttonId === item.name ? 'btn normal selected' : 'btn normal'}
                  onClick={() => {
                    dispatch(selectSport(item.id));
                    dispatch(getCategories(item.id));
                    localStorage.setItem('text', JSON.stringify(item.name)); // Used to determine which menu items to display on third menu
                    localStorage.setItem('buttonId', JSON.stringify(item.name)); // Set active button
                    hideSportsMenu();
                    showTournamentsBar();
                  }}
                >
                  <img
                    src={item.icon ? imgUrl + item.icon : defaultImage}
                    alt=""
                  />
                  <span>{item.name}</span>
                </button>
              </li>
            )) : null}
          </ul>
        </div>
        <div className="show-tournament tournament" id="tournaments">
          <ul className="list-unstyled">
            {text === 'Meilleures Compétitions' && (
              <li className="navs top-event title">
                <span>
                  {t(msg11)}
                </span>
                <div>
                  <button
                    type="button"
                    id="major-tournaments"
                    className="btn btn-close"
                    // onClick={toggleSportsMenu}
                  />
                </div>
              </li>
            )}
            {text === 'Meilleures Compétitions' && (
              (meilleursComp?.length >= 0 && filtered?.length > 0) ? filtered.map((m) => m && (
                <NavLink
                  className="navs "
                  key={m.id}
                  to="/dashboard/matches"
                  onClick={() => {
                    dispatch(getTournamentMatches({ tournamentId: m.id }));
                    toggleMobileMenu();
                  }}
                >
                  <span>{m.name}</span>
                  <img src={arrowRight} alt="" />
                </NavLink>
              )) : null
            )}
            {text !== 'Meilleures Compétitions' && (
              <li className="navs top-event title">
                <span>{t(msg15)}</span>
              </li>
            )}
            {(text !== 'Meilleures Compétitions' && categories?.length > 0) ? (
              categories.map((item) => item && (
                <div key={item.id} className="cat-cont">
                  <button
                    type="button"
                    className="navs tnmt-btn"
                    onClick={() => {
                      dispatch(getCategoryTournaments(item.id));
                      toggleAcc(item.id);
                    }}
                  >
                    <span>{item.name}</span>
                  </button>
                  {tournaments.categoryId === item.id && (
                    <div className={openItemId === item.id ? 'openAcc tmts' : 'acc-closed'}>
                      {tournaments?.tournaments?.length > 0 ? tournaments.tournaments.map((t) => t && (
                        <NavLink
                          className="navs "
                          key={t.id}
                          to="/dashboard/matches"
                          onClick={() => {
                            dispatch(getTournamentId(t.id));
                            dispatch(getTournamentMatches({ tournamentId: t.id }));
                            // localStorage.setItem('marketSelected', JSON.stringify('1x2'));
                            localStorage.setItem('tournamentId', JSON.stringify(t.id)); // To be used at UniversalComponent.jsx to refetch data when page is refreshed
                            toggleMobileMenu();
                          }}
                        >
                          <span>{t.name}</span>
                          <img src={arrowRight} alt="" />
                        </NavLink>
                      )) : null}
                    </div>
                  )}

                </div>
              ))
            ) : null}
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default MobileSideMenu;
