/* eslint  max-len: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import getLanguage from 'components/helpers/getLanguage';
import translationKeys from 'translations/translationKeys';
import tokenText from 'components/helpers/tokenText';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const {
  msg29, msg33, msg34, msg36, msg35,
} = translationKeys;
export const cancelTicket = createAsyncThunk(
  'Tickets/cancelTicket',
  async (obj, thunkAPI) => {
    const lang = getLanguage();
    const token = JSON.parse(localStorage.getItem('token'));
    const requestParams = `lang=${lang}&mat=${obj.agentId}&pin=${obj.agentPwd}&phn=${obj.agentPhone}&chl=${obj.chl}&bid=${obj.bid}&opt=${obj.opt}&dvr=${obj.dvr}&dtp=${obj.dtp}&vers=${obj.ver}&imei=${obj.imei}&kid=${obj.kid}`;
    const api = `/cancel?${requestParams}`;
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    const signatureParam = `/cancel${requestParams}`;
    try {
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
        'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}${JSON.stringify({})}`)}`,
        Authorization: token ? `Bearer ${token}` : '',
      };
      const resp = await client.post(url, {}, { headers });
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.response.data.message || 'An error occurred',
        erc: error.response.data.erc,
      });
    }
  },
);

const initialState = {
  ticketCanceled: false,
  isCancelTicketLoading: false,
  failedCancelTicket: false,
  ticketNotRegistered: false,
  ticketAlreadyCanceled: false,
  showCancelConfirm: false,
  cancelTicketMsg: '',
  errorCancelTicket: '',
};

const cancelTicketSlice = createSlice({
  name: 'cancel-ticket',
  initialState,
  reducers: {
    resetTicketCanceled: (state) => ({
      ...state,
      ticketCanceled: false,
      isCancelTicketLoading: false,
      failedCancelTicket: false,
      ticketNotRegistered: false,
      ticketAlreadyCanceled: false,
      cancelTicketMsg: '',
      errorCancelTicket: '',
    }),
    showCancelConfirmBox: (state) => ({
      ...state,
      showCancelConfirm: true,
    }),
    hideCancelConfirmBox: (state) => ({
      ...state,
      showCancelConfirm: false,
    }),
  },

  extraReducers: (builder) => {
    builder
      .addCase(cancelTicket.pending, (state) => ({
        ...state,
        isCancelTicketLoading: true,
      }))
      .addCase(cancelTicket.fulfilled, (state, { payload }) => {
        let ticketCanceled;
        let cancelTicketMsg;
        let ticketNotRegistered;
        let ticketAlreadyCanceled;
        let failedCancelTicket;
        let isCancelTicketLoading;
        if (payload[0].result === '18') {
          ticketNotRegistered = true;
          isCancelTicketLoading = false;
          cancelTicketMsg = msg33;
        } else if (payload[0].result === '16') {
          ticketAlreadyCanceled = true;
          isCancelTicketLoading = false;
          cancelTicketMsg = msg34;
        } else if (payload[0].result === '24') {
          ticketCanceled = true;
          isCancelTicketLoading = false;
          cancelTicketMsg = msg35;
        } else {
          failedCancelTicket = true;
          isCancelTicketLoading = false;
          cancelTicketMsg = msg36;
        }
        return {
          ...state,
          ticketCanceled,
          isCancelTicketLoading,
          failedCancelTicket,
          ticketNotRegistered,
          cancelTicketMsg,
          ticketAlreadyCanceled,
        };
      })

      .addCase(cancelTicket.rejected, (state, { payload }) => {
        let failedCancelTicket = true;
        let cancelTicketMsg;
        if (payload.erc === '001') {
          failedCancelTicket = true;
          cancelTicketMsg = tokenText;
        } else { cancelTicketMsg = msg29; }
        return {
          ...state,
          isCancelTicketLoading: false,
          failedCancelTicket,
          cancelTicketMsg,
          errorCancelTicket: msg29,
        };
      });
  },
});

export const {
  resetTicketCanceled, showCancelConfirmBox, hideCancelConfirmBox,
} = cancelTicketSlice.actions;

export default cancelTicketSlice.reducer;
