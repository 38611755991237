import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import { userObj } from 'components/common/requestBody';
import translationKeys from 'translations/translationKeys';
import getLanguage from 'components/helpers/getLanguage';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});

const { msg29 } = translationKeys;

export const getCategoryTournaments = createAsyncThunk(
  'sports/getCategoryTournaments',
  async (categoryId, thunkAPI) => {
    const lang = getLanguage();
    const api = `/sport/${categoryId}/0/tournaments?channel_id=${userObj.chl}&lang=${lang}`;
    const signatureParam = `/sport/${categoryId}/0/tournamentschannel_id=${userObj.chl}&lang=${lang}`;
    const url = `/${apiVersion2}${api}`;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}`)}`,
        },
      };
      const resp = await client.get(url, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(msg29);
    }
  },
);

const initialState = {

  tournaments: {},
  isLoading: true,
  error: '',
};

const categoryTournamentsSlice = createSlice({
  name: 'categoryTournaments',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getCategoryTournaments.pending, (state) => ({
        ...state,
        isLoading: true,
      }))

      .addCase(getCategoryTournaments.fulfilled, (state, action) => {
        const updatedTournaments = {
          tournaments: action.payload,
          categoryId: action.meta.arg,
        };
        return {
          ...state,
          tournaments: updatedTournaments,
          isLoading: false,
        };
      })

      .addCase(getCategoryTournaments.rejected, (state) => ({
        ...state,
        isLoading: false,
        error: msg29,
      }));
  },
});

export default categoryTournamentsSlice.reducer;
