/* eslint  max-len: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import { userObj } from 'components/common/requestBody';
import translationKeys from 'translations/translationKeys';
import getLanguage from 'components/helpers/getLanguage';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const { msg29, msg154 } = translationKeys;
export const getTournamentMatches = createAsyncThunk(
  'sports/getTournamentMatches',
  async ({ tournamentId, marketId }, thunkAPI) => {
    const lang = getLanguage();
    let api = `/sport/${tournamentId}/0/matches?marketId=`;
    if (marketId) {
      api += `${marketId}&channel_id=${userObj.chl}&lang=${lang}`;
    } else {
      api += `&channel_id=${userObj.chl}&lang=${lang}`;
    }
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    const signatureParam = `/sport/${tournamentId}/0/matchesmarketId=${marketId || ''}&channel_id=${userObj.chl}&lang=${lang}`;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}`)}`,
        },
      };
      const resp = await client.get(url, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.response.data.message || 'An error occurred',
        erc: error.response.data.erc,
      });
    }
  },
);

const initialState = {

  tournamentMatches: [],
  isLoading: true,
  successGetTournamentMatches: false,
  error: '',
};

const tournamentMatchesSlice = createSlice({
  name: 'tournamentMatches',
  initialState,
  reducers: {
    getTournamentId: (state, { payload }) => {
      const tournamentSelected = payload;
      const updatedTournaments = state.tournamentMatches.map((tournament) => {
        if (tournament.id === payload) {
          localStorage.setItem('tournamentSelected', JSON.stringify(tournament));
          return { ...tournament, selected: true };
        }
        return tournament;
      });
      return {
        ...state,
        tournamentMatches: updatedTournaments,
        tournamentSelected,
      };
    },
    selectFromTmts: (state, { payload }) => {
      const updatedTopMatches = state.tournamentMatches.map((item) => {
        const updatedItem = { ...item };
        updatedItem.matches = updatedItem.matches.map((match) => {
          const updatedMatch = { ...match };
          updatedMatch.outcome = updatedMatch.outcome.map((o) => (o.uniqueId === payload ? { ...o, inBetSlip: true } : o));
          return updatedMatch;
        });
        return updatedItem;
      });

      return {
        ...state,
        tournamentMatches: updatedTopMatches,
        isLoading: false,
      };
    },
    setAllTmstsToFalse: (state) => {
      const updatedTopMatches = state.tournamentMatches.map((item) => {
        const updatedItem = { ...item };
        updatedItem.matches = updatedItem.matches.map((match) => {
          const updatedMatch = { ...match };
          updatedMatch.outcome = updatedMatch.outcome.map((outcome) => ({
            ...outcome,
            inBetSlip: false,
            matchId: match.id,
            uniqueId: match.id + outcome.name,
          }));
          return updatedMatch;
        });
        return updatedItem;
      });

      return {
        ...state,
        tournamentMatches: updatedTopMatches,
        isLoading: false,
      };
    },
    unSelectFromTmts: (state, { payload }) => {
      const updatedTopMatches = state.tournamentMatches.map((item) => {
        const updatedItem = { ...item };
        updatedItem.matches = updatedItem.matches.map((match) => {
          const updatedMatch = { ...match };
          updatedMatch.outcome = updatedMatch.outcome.map((o) => (o.uniqueId === payload ? { ...o, inBetSlip: false } : o));
          return updatedMatch;
        });
        return updatedItem;
      });

      return {
        ...state,
        tournamentMatches: updatedTopMatches,
        isLoading: false,
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getTournamentMatches.pending, (state) => ({
        ...state,
        isLoading: true,
      }))

      .addCase(getTournamentMatches.fulfilled, (state, { payload }) => {
        let error;
        const updatedTournaments = payload.map((item) => {
          const updatedItem = { ...item };
          updatedItem.matches = updatedItem.matches.map((match) => {
            const updatedMatch = { ...match };
            if (updatedMatch.outcome && updatedMatch.outcome.length === 0) {
              error = msg154;
            } else if (updatedMatch.outcome && updatedMatch.outcome.length > 0) {
              error = '';
            }
            updatedMatch.outcome = updatedMatch.outcome.map((outcome) => ({
              ...outcome,
              inBetSlip: false,
              matchId: match.id,
              uniqueId: match.id + outcome.name + outcome.market.name,
              count: match.count,
            }));
            return updatedMatch;
          });
          return updatedItem;
        });

        return {
          ...state,
          tournamentMatches: updatedTournaments,
          successGetTournamentMatches: true,
          isLoading: false,
          error,
        };
      })

      .addCase(getTournamentMatches.rejected, (state) => ({
        ...state,
        isLoading: false,
        error: msg29,
      }));
  },
});

export const {
  selectMatch, setAllTmstsToFalse,
  selectFromTmts, unSelectFromTmts,
} = tournamentMatchesSlice.actions;

export default tournamentMatchesSlice.reducer;
