import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import { userObj } from 'components/common/requestBody';
import translationKeys from 'translations/translationKeys';
import getLanguage from 'components/helpers/getLanguage';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const { msg29 } = translationKeys;
const lang = getLanguage();
const api = `/sport/0/majorTournaments?channel_id=${userObj.chl}&lang=${lang}`;
const signatureParam = `/sport/0/majorTournamentschannel_id=${userObj.chl}&lang=${lang}`;
const url = `/${apiVersion2}${api}`;
export const getMeilleursComp = createAsyncThunk(
  'sports/getMeilleursComp',
  async (thunkAPI) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}`)}`,
        },
      };
      const resp = await client.get(url, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(msg29);
    }
  },
);

const initialState = {
  meilleursCompSuccess: false,
  meilleursComp: [],
  isLoading: true,
  error: '',
};

const meilleursCompSlice = createSlice({
  name: 'meilleursCompetition',
  initialState,
  reducers: {
    getTournamentId: (state, { payload }) => {
      const tournamentSelected = payload;
      const updatedTournaments = state.meilleursComp.map((tournament) => {
        if (tournament.id === payload) {
          localStorage.setItem('tournamentSelected', JSON.stringify(tournament));
          return { ...tournament, selected: true };
        }
        return tournament;
      });
      return {
        ...state,
        meilleursComp: updatedTournaments,
        tournamentSelected,
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getMeilleursComp.pending, (state) => ({
        ...state,
        meilleursCompSuccess: false,
        isLoading: true,
      }))
      .addCase(getMeilleursComp.fulfilled, (state, { payload }) => {
        const updatedArr = payload && payload.map((item) => {
          const formattedName = item.name.toLowerCase().replace(/\s/g, '');
          return {
            ...item,
            path: formattedName,
          };
        });
        return {
          ...state,
          meilleursComp: updatedArr,
          isLoading: false,
          meilleursCompSuccess: true,
        };
      })

      .addCase(getMeilleursComp.rejected, (state) => ({
        ...state,
        isLoading: false,
        meilleursCompSuccess: false,
        error: msg29,
      }));
  },
});

export const { getTournamentId } = meilleursCompSlice.actions;

export default meilleursCompSlice.reducer;
