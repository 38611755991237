import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { balanceObj, userObj } from 'components/common/requestBody';
import { getBalance } from 'redux/solde/getBalanceSlice';
import translationKeys from 'translations/translationKeys';
import fileText from '../../../../assets/images/mathys/svg/fileText.svg';

const Solde = () => {
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const handleChange = () => {
    const dateLabel = document.getElementById('datelabel').value;
    if (dateLabel !== '') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const date = new Date(e.target.elements.datelabel.value);
    const timestamp = date.getTime();
    dispatch(getBalance({
      ...balanceObj,
      date: timestamp,
      uuid: userInfo.agentId,
      agentPwd: userInfo.agentPwd,
      imei: userObj.imei,
      ime: userObj.imei,
    }));
  };
  const { msg69, msg63, msg55 } = translationKeys;

  return (
    <>
      <div className="details history">
        <div className="header">
          <img src={fileText} alt="" />
          {t(msg69)}
        </div>
        <div className="content">
          <form action="" onSubmit={handleSubmit} className="solde-form">
            <div className="content-grid">
              <div className="mb-3 input-wrap">
                <label htmlFor="datelabel" className="form-label date-label">
                  {t(msg63)}
                  <input
                    type="date"
                    className="form-control solde-input"
                    id="datelabel"
                    placeholder="jj/mm/aaaa"
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <button
                type="submit"
                className="btn btn-secondary"
                disabled={disabled}
              >
                <span>{t(msg55)}</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Solde;
