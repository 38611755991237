/* eslint  max-len: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import { userObj } from 'components/common/requestBody';
import translationKeys from 'translations/translationKeys';
import getLanguage from 'components/helpers/getLanguage';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const { msg29 } = translationKeys;
const lang = getLanguage();
const api = `/sport/event/topMatches/filter?markets=1&channel_id=${userObj.chl}&lang=${lang}`;
const signatureParam = `/sport/event/topMatches/filtermarkets=1&channel_id=${userObj.chl}&lang=${lang}`;
const url = `${apiBaseUrl}/${apiVersion2}${api}`;
export const getAllTopMatches = createAsyncThunk(
  'sports/getAllTopMatches',
  async (data, thunkAPI) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}${JSON.stringify(data)}`)}`,
        },
      };
      const resp = await client.post(url, data, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(msg29);
    }
  },
);

const initialState = {

  allTopMatches: [],
  filters: [],
  isLoading: true,
  error: '',
};

const allTopMatchesSlice = createSlice({
  name: 'allTopMatches',
  initialState,
  reducers: {
    selectItem: (state, { payload }) => {
      const updatedTopMatches = state.allTopMatches.map((match) => ({
        ...match,
        categories: match?.categories?.map((cat) => ({
          ...cat,
          tournaments: cat?.tournaments?.map((t) => ({
            ...t,
            matches: t?.matches?.map((m) => ({
              ...m,
              outcome: m?.outcome?.map((o) => (o.uniqueId === payload ? { ...o, inBetSlip: true } : o)),
            })),
          })),
        })),
      }));
      return {
        ...state,
        allTopMatches: updatedTopMatches,
        isLoading: false,
      };
    },
    selectSport: (state, { payload }) => {
      const updatedMatches = state.allTopMatches.filter((item) => item.id === payload);
      return {
        ...state,
        allTopMatches: updatedMatches,
        isLoading: false,
      };
    },

    unSelectFromTopmatch: (state, { payload }) => {
      const updatedTopMatches = state.allTopMatches.map((match) => ({
        ...match,
        categories: match?.categories?.map((cat) => ({
          ...cat,
          tournaments: cat?.tournaments?.map((t) => ({
            ...t,
            matches: t?.matches?.map((m) => ({
              ...m,
              outcome: m?.outcome?.map((o) => (o.uniqueId === payload ? { ...o, inBetSlip: false } : o)),
            })),
          })),
        })),
      }));
      return {
        ...state,
        allTopMatches: updatedTopMatches,
        isLoading: false,
      };
    },
    setAllToFalse: (state) => {
      const updatedTopMatches = state.allTopMatches.map((match) => ({
        ...match,
        categories: match?.categories?.map((cat) => ({
          ...cat,
          tournaments: cat?.tournaments?.map((t) => ({
            ...t,
            matches: t?.matches?.map((m) => ({
              ...m,
              outcome: m?.outcome?.map((outcome) => ({
                ...outcome,
                inBetSlip: false,
              })),
            })),
          })),
        })),
      }));

      return {
        ...state,
        meilleursParis: updatedTopMatches,
        isLoading: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTopMatches.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(getAllTopMatches.fulfilled, (state, { payload }) => {
        const filters = payload.filter((item) => item.count > 0);
        return {
          ...state,
          allTopMatches: payload,
          filters,
          isLoading: false,
        };
      })
      .addCase(getAllTopMatches.rejected, (state) => ({
        ...state,
        isLoading: false,
        error: msg29,
      }));
  },
});

export const {
  selectItem, unSelectFromTopmatch,
  setAllToFalse, selectSport,
} = allTopMatchesSlice.actions;

export default allTopMatchesSlice.reducer;
