import { createSlice } from '@reduxjs/toolkit';
import { homeData } from 'homeData';

const initialState = {
  homeList: [],
  isLoading: true,
  error: '',
};

const homeDashBoardSlice = createSlice({
  name: 'homeDashBoard',
  initialState,
  reducers: {
    homeListData: (state) => ({
      ...state,
      homeList: homeData,
    }),
  },
});

export const { homeListData } = homeDashBoardSlice.actions;
export default homeDashBoardSlice.reducer;
