/* eslint  max-len: 0 */
import React, { useEffect, useState } from 'react';
import {
  Route, Routes, Navigate, useNavigate, useLocation,
} from 'react-router-dom';
// import axios from 'axios';
import { useDispatch } from 'react-redux';
import { getBetConfigs, getEventRestrictions } from 'redux/configs/betConfigsSlice';
import { logout } from 'redux/login/loginSlice';
import { homeListData } from 'redux/homeDashboard/homeDashBoardSlice';
import { getCurrencyConfigs } from 'redux/configs/currencyConfigsSlice';
import PageNotFound from 'components/pageNotFound/PageNotFound';
// import { getSystemConfigs } from 'redux/configs/systemConfigsSlice';
import { getRefresToken } from 'redux/login/refreshTokenSlice';
import defaultSportId from 'components/helpers/defaultSport';
import './App.scss';
import Login from 'components/sessions/Login';
import Dashboard from 'components/dashboard/Dashboard';
import SinglePage from 'components/dashboard/SinglePage';
import Layout from './components/Layout';

const App = () => {
  const [isActive, setIsActive] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem('token') || '';
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));

  useEffect(() => {
    const handleUserActivity = () => {
      setIsActive(true);
    };

    const handleInactive = () => {
      setIsActive(false);
    };

    document.addEventListener('mousemove', () => {
      handleUserActivity();
    });
    document.addEventListener('keydown', () => {
      handleUserActivity();
    });
    document.addEventListener('touchstart', () => {
      handleUserActivity();
    });
    document.addEventListener('click', () => {
      handleUserActivity();
    });
    setIsActive(true);
    const timeout = setTimeout(() => {
      handleInactive();
    }, 30 * 60 * 1000); // User is considered inactive after 30 minutes of inactivity

    // Cleanup function to remove event listeners and clear timeout
    return () => {
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
      document.removeEventListener('touchstart', handleUserActivity);
      document.removeEventListener('click', handleUserActivity);
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (!token || !userInfo) {
      dispatch(logout());
      navigate('/');
    } else {
      const expireAt = localStorage.getItem('expireAt');
      if (expireAt && (expireAt < Date.now())) {
        if (isActive) {
          dispatch(getRefresToken());
          console.log('Token expired and user is active. Refreshing token...');
        } else {
          console.log('Token expired and user is inactive. Logging out...');
          dispatch(logout());
          window.location.reload();
        }
      }
    }
  }, [isActive, dispatch]);
  useEffect(() => {
    if (!localStorage.getItem('lang')) {
      const userLanguage = navigator.language.split('-')[0]; // Extract the language code e.g en-US -> en
      localStorage.setItem('lang', userLanguage); // Make language available in local storage for api calls
    }

    if (!localStorage.getItem('sportSelected')) {
      localStorage.setItem('sportSelected', JSON.stringify(defaultSportId)); // Set default sport to football when you click on Aujourd'hui or Today
    }
  }, [pathname]);
  useEffect(() => {
    dispatch(getBetConfigs()); // Load configs when app loads. These configs are found in betConfigsSlice at getBetConfigs.fulfilled
    dispatch(getEventRestrictions()); // Load event restrictions when app loads. These configs are found in betConfigSlice at getEventRestrictions.fulfilled
    dispatch(homeListData());
    dispatch(getCurrencyConfigs()); // Load currencyconfigs used on bet slips calculations
  }, [dispatch]);
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route
          path="/"
          element={token ? <Navigate to="dashboard/home" /> : <Login />}
        />
        {token && (
        <Route path="dashboard" element={<Dashboard />}>
          <Route path="" element={<Navigate to="/dashboard/home" replace />} />
          <Route path=":id" element={<SinglePage />} />
        </Route>
        )}
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default App;
