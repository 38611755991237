const formatData = (obj) => {
  const formattedData = Object.keys(obj || {}).reduce((acc, key) => {
    const markets = Array.isArray(obj[key]) // Check if obj[key] is an array
      ? obj[key].map((item) => {
        const parsedItem = JSON.parse(item);
        const { marketId, specifierid } = parsedItem;
        const marketIdentifier = specifierid ? `${marketId}*${specifierid}` : marketId;
        return marketIdentifier;
      }).join(',')
      : ''; // If it's not an array, set markets to an empty string
    return { ...acc, [key]: markets };
  }, {});
  return formattedData;
};

export default formatData;
